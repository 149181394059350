import {
  AtSignIcon,
  CalendarIcon,
  EditIcon,
  MoonIcon,
  SunIcon,
  StarIcon,
} from "@chakra-ui/icons";
import { Image, List, ListIcon, ListItem, Stack } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/hypecircle.jpg";
import DollarIcon from "../assets/DollarIcon";

export default function Sidebar() {
  return (
    <List
      color="white"
      fontSize={{ base: "0.9rem", md: "1.2rem" }}
      spacing={{ base: 1, md: 4 }}
    >
      <Stack gap="2" direction={{ base: "row", md: "column" }}>
        <ListItem>
          <Image
            src={Logo}
            boxSize={{ base: "60px", md: "120px" }}
            objectFit="cover"
            ml={{ base: 3, md: 10 }}
            mr={{ base: 3, md: 10 }}
            mb={{ base: 3, md: 10 }}
            borderRadius={"50%"}
          />
        </ListItem>
        <ListItem>
          <NavLink to="/dashboard">
            <ListIcon as={CalendarIcon} color="white" />
            Dashboard
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/dashboard/addNewMember">
            <ListIcon as={EditIcon} color="white" />
            Add Member
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/dashboard/attendance">
            <ListIcon as={AtSignIcon} color="white" />
            Attendance
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/dashboard/payments">
            <ListIcon as={DollarIcon} color="white" />
            Payments
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/dashboard/trainers">
            <ListIcon as={SunIcon} color="white" />
            Trainers
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/dashboard/complaints">
            <ListIcon as={MoonIcon} color="white" />
            Complaints
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/dashboard/reviews">
            <ListIcon as={StarIcon} color="white" />
            Reviews
          </NavLink>
        </ListItem>
      </Stack>
    </List>
  );
}
