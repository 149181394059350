import { useState, useEffect } from "react";
import {
  Center,
  Card,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
} from "@chakra-ui/react";
import UserSearch from "./UserSearchBar";
import axios from "axios";
import Swal from "sweetalert2";
import BASE_URL from "../utils/BaseURL";
import EditDate, { getCurrentDateRev } from "../utils/EditDate";
import { EditTime } from "../utils/EditTime";
// import { DeleteIcon } from "@chakra-ui/icons";

export default function AttendanceTable() {
  const [TodaysAttendance, setTodaysAttendance] = useState([]);

  const GetAllAttendance = () => {
    let current = getCurrentDateRev();
    let url = `${BASE_URL}/api/attendance/getAllAttendance?date=${current}`;

    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let filteredAttendance = res?.data?.Attendances;
        setTodaysAttendance(filteredAttendance);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "500 Server Error",
          text: err,
        });
      });
  };
  useEffect(() => {
    let makereq = localStorage.getItem("AttendanceLogin");
    if (makereq) {
      GetAllAttendance();
    }
    return () => (makereq = false);
  }, []);

  return (
    <>
      <Flex
        pt="10px"
        pr={{ md: "200px" }}
        pl={{ md: "200px" }}
        pd={{ md: "10px" }}
        m="5"
        alignItems="center"
        gap={{ base: "10px", md: "40px" }}
      >
        <UserSearch GetAllAttendance={GetAllAttendance} />
      </Flex>

      <Card mt="10" minHeight="75vh">
        <Heading textAlign="center" size="lg">
          Attendance Table
        </Heading>
        <TableContainer
          border="0.5px solid"
          p={{ md: "2" }}
          mx={{ md: "50" }}
          borderRadius="5px"
        >
          <Table
            variant="striped"
            colorScheme="red"
            style={{ overflowY: "scroll" }}
          >
            <Thead>
              <Tr>
                <Th>S.No.</Th>
                <Th>Name</Th>
                <Th>Contact</Th>
                <Th>Date</Th>
                <Th>Check in Time</Th>
                <Th>Start Date</Th>
                <Th>End Date</Th>
                <Th> Status</Th>
                {/* <Th>Action</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {TodaysAttendance.length > 0 &&
                TodaysAttendance.reverse().map((mark, index) => {
                  let currentdate = new Date();
                  let enddate = new Date(
                    mark?.userID?.end_date?.split("-").reverse().join("-")
                  );
                  let isExpired = currentdate >= enddate;
                  return (
                    <Tr key={index + "attendance" + mark?.membershipID}>
                      <Td>{index + 1}</Td>
                      <Td>{mark?.name}</Td>
                      <Td>{mark?.contact}</Td>
                      <Td>{EditDate(mark?.date)}</Td>
                      <Td>{EditTime(mark?.check_in_time)}</Td>
                      {/* <Td>{mark?.userID?.pending_amount || "Not Specified"}</Td> */}
                      <Td>{EditDate(mark?.userID?.start_date)}</Td>
                      <Td>{EditDate(mark?.userID?.end_date)}</Td>

                      <Td>
                        <Button
                          size={"xs"}
                          colorScheme={isExpired ? "red" : "green"}
                        >
                          {isExpired ? "Expired" : "Active"}
                        </Button>
                      </Td>
                      {/* <Td>
                        <Button
                          size={"xs"}
                          colorScheme="red"
                          onClick={() => handleDeleteAttendance(mark._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Td> */}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
          {TodaysAttendance.length <= 0 && (
            <Center m="5px">No Attendance Available</Center>
          )}
        </TableContainer>
      </Card>
    </>
  );
}
