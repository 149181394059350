import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import Dashboard from "./pages/Dashboard";
import AddNew from "./pages/AddNew";
import Attendance from "./pages/Attendance";
import Login from "./pages/Login";
import ChangePassword from "./components/ChangePassword";
import SplashScreen from "./components/SplashScreen";
import AdminAttendanceTable from "./components/AdminAttendanceTable";
import ChangeAttendancePassword from "./components/ChangeAttendancePassword";
import ResetPassword from "./pages/ResetPassword";
import EnterEmail from "./pages/EnterEmail";
import Trainers from "./pages/Trainers";
import Complaints from "./pages/Complaints";
import AddTrainer from "./pages/AddNewTrainer";
import AddNewComplaint from "./pages/AddComplaint";
import Reviews from "./pages/Reviews";
import AddReview from "./pages/AddReview";
import TrainerReviews from "./pages/TrainerReviews";
import EditTrainer from "./pages/EditTrainer";
import Payments from "./pages/Payments";
require("./App.css");

function App() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showSplash && <SplashScreen />}
      {!showSplash && (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<RootLayout />}>
            <Route index element={<Dashboard />} />
            <Route
              path="/dashboard/changepassword"
              element={<ChangePassword />}
            />

            <Route path="/dashboard/addnewmember" element={<AddNew />} />
            <Route
              path="/dashboard/attendance"
              element={<AdminAttendanceTable />}
            />
            <Route path="/dashboard/trainers" element={<Trainers />} />
            <Route path="/dashboard/trainers/add" element={<AddTrainer />} />
            <Route path="/dashboard/complaints" element={<Complaints />} />
            <Route path="/dashboard/payments" element={<Payments />} />

            <Route path="/dashboard/reviews" element={<Reviews />} />
            <Route
              path="/dashboard/reviews/:trainerId"
              element={<TrainerReviews />}
            />
            <Route
              path="/dashboard/trainers/edit/:id"
              element={<EditTrainer />}
            />
          </Route>

          <Route
            path="/attendance/complaints/add"
            element={<AddNewComplaint />}
          />
          <Route path="/attendance/reviews/add" element={<AddReview />} />
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/reset-pass/:id/:email" element={<ResetPassword />} />
          <Route path="/reset-pass-email" element={<EnterEmail />} />
          <Route
            path="/attendance/changepassword"
            element={<ChangeAttendancePassword />}
          />
        </Routes>
      )}
    </>
  );
}

export default App;
