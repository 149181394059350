import { Input, Heading, Button, Box, Stack } from "@chakra-ui/react";
import { CloseIcon, RepeatIcon } from "@chakra-ui/icons";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import axios from "axios";
import BASE_URL from "../utils/BaseURL";
import { getCurrentDateRev } from "../utils/EditDate";

const Filters = ({ GetAllAttendance, setAttendance }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setfilteredUsers] = useState([]);

  const [Currdate, setCurrdate] = useState("");

  const HandleSelectUser = (user) => {
    setfilteredUsers([]);
    setSearchQuery(`${user.contact} - Name: ${user.full_name}`);
    HandleSearchAttendanceByContact(user._id);
  };

  const HandleSearchAttendanceByContact = (userId) => {
    axios
      .get(
        `${BASE_URL}/api/attendance/getAllAttendanceByUserId?search=${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        let filteredAttendance = res?.data?.Attendances?.sort(
          (a, b) => a.date - b.date
        );
        if (filteredAttendance.length <= 0) {
          return Swal.fire({
            icon: "info",
            text: "No User found with given email",
          });
        }
        setAttendance(filteredAttendance);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "500 Server Error",
          text: err,
        });
      });
  };
  const ReturnCurrDate = () => {
    const date = getCurrentDateRev();
    setCurrdate(date);
    return date;
  };
  const HandleDateChange = (e) => {
    let currdate = e.target.value.split("-").reverse().join("-");
    setCurrdate(e.target.value);
    GetAllAttendance(currdate);
  };
  const Refresh = () => {
    ReturnCurrDate();
    setSearchQuery("");
    GetAllAttendance();
  };
  const closedropdown = (e) => {
    if (!e.target.matches("[data-dropdown]")) setfilteredUsers([]);
  };

  useEffect(() => {
    ReturnCurrDate();
  }, []);

  useEffect(() => {
    const GetFilteredUsers = () => {
      let SearchURL = `${BASE_URL}/api/users/searchUser?search=${searchQuery}`;
      axios
        .get(SearchURL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setfilteredUsers(res?.data?.Users);
        })
        .catch((err) => console.log(err));
    };
    const Timer = setTimeout(() => {
      if (searchQuery !== "") {
        GetFilteredUsers();
      }
    }, 700);
    return () => clearTimeout(Timer);
  }, [searchQuery]);

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      gap={{ base: "3px", md: "10px" }}
      justifyContent="end"
      alignItems="center"
      onClick={closedropdown}
    >
      <Heading size={{ base: "xs", md: "sm" }}>Filters:</Heading>
      <Box style={{ position: "relative" }}>
        <Input
          type="number"
          placeholder="Search Attendance of User by Mobile No."
          width={{ base: "280px", md: "400px" }}
          value={searchQuery}
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
        />
        {filteredUsers.length > 0 && (
          <ul style={UlStyles} data-dropdown>
            {filteredUsers.map((user, index) => (
              <li
                onClick={() => HandleSelectUser(user)}
                style={liststyles}
                key={index + "xys"}
              >
                {`${user.contact} - Name: ${user.full_name}`}
              </li>
            ))}
          </ul>
        )}
      </Box>

      <Button colorScheme="gray" onClick={() => setSearchQuery("")}>
        <CloseIcon />
      </Button>
      {/* Date Filter */}
      <Input
        type="date"
        value={Currdate}
        placeholder="Enter Date"
        width={{ md: "200px" }}
        onChange={HandleDateChange}
      />
      <Button colorScheme="pink" onClick={Refresh} leftIcon={<RepeatIcon />}>
        Refresh
      </Button>
    </Stack>
  );
};
const liststyles = {
  padding: "6px 12px",
  border: "0.1px solid pink",
  borderRadius: "2px",
};
const UlStyles = {
  position: "absolute",
  background: "white",
  zIndex: "5",
  width: "100%",
  listStyle: "none",
  maxHeight: "500px",
  overflowY: "scroll",
  overflowX: "hidden",
};
export default Filters;
