export function EditTime(time24Hour) {
    const [hours, minutes] = time24Hour.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12Hour = (hours % 12) || 12; // Handle 0 as 12
    const time12Hour = `${hours12Hour}:${minutes} ${period}`;

    return time12Hour;
}

export const GetCurrTime = () => {
    const currdate = new Date();
    let hours = currdate.getHours();
    let minutes = currdate.getMinutes();

    if (hours < 10) hours = `0${hours}`;
    if (minutes < 10) minutes = `0${minutes}`;

    return `${hours}:${minutes}`;
};