import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stats from "../components/Stats";
import MembersList from "./MembersList";
import axios from "axios";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import { getCurrentDateRev } from "../utils/EditDate";

export default function Dashboard() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  const [TotalMembers, setTotalMembers] = useState(0);
  const [MembershipDue, setMembershipDue] = useState(0);
  const [MonthlyRevenue, setMonthlyRevenue] = useState(0);
  const [Expired, setExpired] = useState([]);
  const [Expiring, setExpiring] = useState([]);
  const [Paused, setPaused] = useState([]);
  const [Active, setActive] = useState([]);

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate("/");
    }
    let makereq = localStorage.getItem("isLoggedIn");

    let CurrentDate = getCurrentDateRev();
    let body = { CurrentDate };

    if (makereq) GetDashboardDetails(body);
    return () => (makereq = false);
  }, [isLoggedIn, navigate]);

  const GetDashboardDetails = (body) => {
    axios
      .post(`${BASE_URL}/api/users/getDashboardDetails`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTotalMembers(res?.data?.TotalUsers);
        setMonthlyRevenue(res?.data?.MonthlyRevenue);
        setMembershipDue(res?.data?.MembershipDue);
        setExpired(res?.data?.Expired);
        setExpiring(res?.data?.Expiring);
        setPaused(res?.data?.Paused);
        setActive(res?.data?.Active);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  return (
    <>
      {isLoggedIn && (
        <Stats Data={{ TotalMembers, MembershipDue, MonthlyRevenue }} />
      )}
      {isLoggedIn && (
        <MembersList
          TotalMembers={TotalMembers}
          Active={Active}
          Expiring={Expiring}
          Expired={Expired}
          Paused={Paused}
        />
      )}
    </>
  );
}
