import axios from "axios";
import React, { useEffect, useState } from "react";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import {
  Button,
  TabPanel,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import PaginationBox from "./Pagination";
import EditDate, { convertDate, splitReverseByDash } from "../utils/EditDate";
import EditUserModal from "./EditUserModal";
import ExtendMembershipForm from "./ExtendMembershipForm";
import PauseMembershipForm from "./PauseMembershipForm";
import ResumeMembership from "./ResumeMembership";

const PausedList = ({ Paused }) => {
  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [AllUsers, setAllUsers] = useState([]);
  const [OneUser, setOneUser] = useState({});

  const [IsEdit, setIsEdit] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isExtendMembershipOpen,
    onOpen: onExtendMembershipOpen,
    onClose: onExtendMembershipClose,
  } = useDisclosure();
  const {
    isOpen: isPauseMembershipOpen,
    onOpen: onPauseMembershipOpen,
    onClose: onPauseMembershipClose,
  } = useDisclosure();
  const {
    isOpen: isResumeMembershipOpen,
    onOpen: onResumeMembershipOpen,
    onClose: onResumeMembershipClose,
  } = useDisclosure();
  const [page, setpage] = useState(1);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOneUser((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const GetUsers = (Page = 1) => {
    axios
      .get(`${BASE_URL}/api/users/getAllUsers?page=${Page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setAllUsers(res?.data?.Users);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    setIsEdit(false);
    if (!isLoggedIn) {
      return navigate("/");
    }
    let makereq = localStorage.getItem("isLoggedIn");
    if (makereq) GetUsers(page);
    return () => (makereq = false);
  }, [isLoggedIn, navigate, page]);

  return (
    <TabPanel>
      <TableContainer border="0.5px solid" borderRadius="5px" p={{ md: 2 }}>
        <Table
          variant="striped"
          colorScheme="red"
          size="sm"
          style={{ overflowY: "scroll" }}
        >
          <Thead>
            <Tr>
              <Th sx={headCellStyle}>S.No</Th>
              <Th sx={headCellStyle}>Membership ID</Th>
              <Th sx={headCellStyle}>Name</Th>
              <Th sx={headCellStyle}>{"Joining\nDate"}</Th>
              <Th sx={headCellStyle}>{"Membership\nEnd Date"}</Th>
              <Th sx={headCellStyle}>Mobile </Th>
              <Th sx={headCellStyle}>{"Balance\nDue Date"}</Th>
              <Th sx={headCellStyle}>{"Payment\nMode"}</Th>
              <Th sx={headCellStyle}>{"Balance\nAmount"}</Th>
              <Th sx={headCellStyle}>{"Total \nAmount"}</Th>
              <Th sx={headCellStyle}>Status</Th>
              <Th sx={headCellStyle}>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Paused.length > 0 &&
              Paused.map((user, index) => (
                <Tr key={index + "allusers" + user.membershipID}>
                  <Td sx={cellStyle}>{index + 1}</Td>
                  <Td sx={cellStyle}>
                    <b>{user?.membershipID}</b>
                  </Td>
                  <Td sx={cellStyle}>{user?.full_name}</Td>

                  <Td sx={cellStyle}>
                    {EditDate(convertDate(user?.createdAt))}
                  </Td>
                  <Td sx={cellStyle}>{EditDate(user?.end_date)}</Td>
                  <Td sx={cellStyle}>
                    {user?.contact ? user.contact : "Not Provided"}
                  </Td>
                  <Td sx={cellStyle}>
                    {EditDate(user.balance_pay_date) || "Not Provided"}
                  </Td>
                  <Td sx={cellStyle}>{user?.mode_of_payment}</Td>
                  <Td sx={cellStyle}>{user?.pending_amount}</Td>
                  <Td sx={cellStyle}>{user?.amount}</Td>
                  <Td sx={{ cellStyle, fontWeight: 600 }}>
                  {user.isPaused ? "Paused" : user?.expiring_status?.toUpperCase()}
                  </Td>
                  <Td sx={cellStyle}>
                    <Button
                      size="xs"
                      colorScheme="red"
                      onClick={() => {
                        setOneUser({
                          ...user,
                          start_date: splitReverseByDash(user.start_date),
                          end_date: splitReverseByDash(user.end_date),
                          balance_pay_date: splitReverseByDash(
                            user.balance_pay_date
                          ),
                        });
                        onOpen();
                      }}
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <EditUserModal
        isOpen={isOpen}
        OneUser={OneUser}
        setOneUser={setOneUser}
        handleInputChange={handleInputChange}
        GetUsers={GetUsers}
        IsEdit={IsEdit}
        extendMembership={onExtendMembershipOpen}
        pauseMembership={onPauseMembershipOpen}
        resumeMembership ={onResumeMembershipOpen}
        setIsEdit={setIsEdit}
        onClose={onClose}
      />
      <ExtendMembershipForm
        onClose={onExtendMembershipClose}
        GetUsers={GetUsers}
        setIsEdit={setIsEdit}
        OneUser={OneUser}
        isOpen={isExtendMembershipOpen}
      />
       <PauseMembershipForm
        onClose={onPauseMembershipClose}
        GetUsers={GetUsers}
        OneUser={OneUser}
        isOpen={isPauseMembershipOpen}
      />
      <ResumeMembership
        onClose={onResumeMembershipClose}
        GetUsers={GetUsers}
        OneUser={OneUser}
        isOpen={isResumeMembershipOpen}
      />

    </TabPanel>
  );
};
const headCellStyle = {
  color: "black",
  padding: "5px",
  overflow: "hidden",
  whiteSpace: "pre",

  wordWrap: "break-word",
};
const cellStyle = {
  padding: "10px",
};
export default PausedList;