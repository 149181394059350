import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Center, Button, Stack, Input } from "@chakra-ui/react";
import React from "react";

const PaginationBox = ({ TotalMembers, setpage, page }) => {
  const Pages = Math.ceil(TotalMembers / 10);
  const HandleLeft = () => {
    if (page === 1) return;
    setpage((prev) => prev - 1);
  };
  const HandleRight = () => {
    if (page === Pages) return;
    setpage((prev) => prev + 1);
  };
  return (
    <Stack m="15px" gap="6px" alignItems={"end"} justifyContent={"center"}>
      <Center
        gap="6px"
        border={"1px solid grey"}
        py={1}
        px={2}
        width={"fit-content"}
        borderRadius={"5px"}
      >
        <Button variant={"unstyled"} mx={2} my={1}>
          Total Pages: {Pages}
        </Button>

        <Button
          variant={"solid"}
          onClick={HandleLeft}
          leftIcon={<ChevronLeftIcon />}
        >
          Prev
        </Button>
        <Input
          variant={"ghost"}
          value={page}
          textAlign={"center"}
          background={"#fed7d7"}
          fontWeight={"500"}
          w={"60px"}
          onChange={(e) => {
            let x = e.target.value;
            if (x > 0 && x < Pages) setpage(+x);
          }}
        />

        <Button
          variant={"solid"}
          onClick={HandleRight}
          rightIcon={<ChevronRightIcon />}
        >
          Next
        </Button>
        <Button variant="outline" onClick={() => setpage(1)}>
          First
        </Button>
        <Button variant="outline" onClick={() => setpage(Pages)}>
          Last
        </Button>
      </Center>
    </Stack>
  );
};

export default PaginationBox;
