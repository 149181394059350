const AWS = require('aws-sdk');
const s3 = new AWS.S3({
    accessKeyId: "AKIAYW3YSJZZUNDJJWO7",
    secretAccessKey: "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",
    region: "ap-south-1"
});

const uploadFileToS3 = (file) => {
    const params = {
        Bucket: "hypegym",
        Key: file.name,
        Body: file
    };

    return s3.upload(params).promise();
}
module.exports = uploadFileToS3;


