import {
  Container,
  FormLabel,
  Input,
  Select,
  Button,
  Grid,
  GridItem,
  Text,
  FormControl,
  FormErrorMessage,
  Box,
} from "@chakra-ui/react";

import { useState } from "react";

import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { splitReverseByDash } from "../utils/EditDate";

function ReturnCurrDate() {
  const date = new Date();
  let dat = new Date().getDate();
  let month = date.getMonth() + 1;
  if (dat < 10) month = `0${dat}`;
  if (month < 10) month = `0${month}`;
  let str = `${date.getFullYear()}-${month}-${dat}`;
  return str;
}

let initial = {
  email: "",
  password: "",
  full_name: "",
  contact: "",
  remark: "",
  emergency_Contact_Name: "",
  emergency_Contact_Number: "",
  health_issue: "",
  fitness_goal: "",
  start_date: ReturnCurrDate(),
  balance_pay_date: "",
  end_date: "",
  amount: "",
  pending_amount: 0,
  paid_amount: "",
  mode_of_payment: "",
  // gender: "",
  // DOB: "",
  // weight: "",
  // profession: "",
  // height: "",
};

export default function AddNew() {
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);

  const [formData, setFormData] = useState(initial);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePendingAmountChange = (event) => {
    const { value } = event.target;
    if (+value > +formData.amount) return;
    setFormData((prevFormData) => ({
      ...prevFormData,
      pending_amount: value,
      paid_amount: +formData.amount - value || 0,
    }));
  };

  const validate = () => {
    let errors = {};

    if (!formData.full_name.trim()) {
      errors.full_name = "Full name is required";
    }
    if (!formData.contact) {
      errors.contact = "Contact number is required";
    } else if (formData.contact.length !== 10) {
      errors.contact = "Contact number must be 10 digits";
    }

    if (!formData.emergency_Contact_Name.trim()) {
      errors.emergency_Contact_Name = "Emergency contact name is required";
    }
    if (!formData.emergency_Contact_Number) {
      errors.emergency_Contact_Number = "Emergency contact number is required";
    } else if (formData.emergency_Contact_Number.length !== 10) {
      errors.emergency_Contact_Number =
        "Emergency contact number must be 10 digits";
    }
    if (!formData.start_date) {
      errors.start_date = "Start date is required";
    }
    if (!formData.end_date) {
      errors.end_date = "End date is required";
    }
    if (!formData.amount) {
      errors.amount = "Amount is required";
    }

    if (!formData.mode_of_payment) {
      errors.mode_of_payment = "Mode of payment is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setloading(true);

    if (!validate()) {
      setloading(false);
      return;
    }

    let body = {
      ...formData,
    };
    body.full_name = formData?.full_name.trim();
    body.paid_amount = +formData?.paid_amount;
    body.pending_amount = +formData?.pending_amount;
    body.amount = +formData?.amount;
    body.gender = formData?.gender || "Male";
    body.fitness_goal = formData?.fitness_goal || "fitness";
    body.start_date = splitReverseByDash(formData?.start_date);
    body.end_date = splitReverseByDash(formData?.end_date);
    body.balance_pay_date = splitReverseByDash(formData?.balance_pay_date);
    body.DOB = splitReverseByDash(formData?.DOB);

    let response = await fetch(`${BASE_URL}/api/users/register`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });

    if (response.status === 201) {
      setloading(false);
      setFormData(initial);
      navigate("/dashboard");
      Swal.fire({
        icon: "success",
        title: "New Member Added",
        text: "Notification sent to user",
      });
    } else if (response.status === 409) {
      setloading(false);
      Swal.fire({
        icon: "warning",
        title:
          "Member Already Exists with same contact details, Please edit existing member!",
      });
    } else {
      setloading(false);
      Swal.fire({
        icon: "error",
        title: "400 Error Bad Request",
      });
    }
  };

  return (
    <Box mx={"10px"}>
      <Text mt="10px" mb="2" fontSize="1.5rem">
        Enter details of member:
      </Text>
      <Container
        as="form"
        maxW="container"
        border="0.4px solid"
        borderRadius="5px"
        mb="10"
        onSubmit={handleSubmit}
      >
        <Grid templateColumns="repeat(6, 1fr)" p="10px">
          <GridItem as="div" colSpan={{ base: 6, md: 3 }} p="10px">
            <FormControl isInvalid={errors.full_name}>
              <FormLabel>Enter Full Name</FormLabel>
              <Input
                placeholder="Enter Full Name"
                type="text"
                name="full_name"
                value={formData?.full_name}
                onChange={handleInputChange}
              />
              {errors.full_name && (
                <FormErrorMessage>{errors.full_name}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 3 }} p="10px">
            <FormControl isInvalid={errors.contact}>
              <FormLabel>Contact number</FormLabel>
              <Input
                placeholder="Enter Contact Number"
                type="number"
                name="contact"
                value={formData?.contact}
                onChange={handleInputChange}
              />
              {errors.contact && (
                <FormErrorMessage>{errors.contact}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormControl isInvalid={errors.email}>
              <FormLabel>Email ID</FormLabel>
              <Input
                placeholder="Enter Email"
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormLabel>Goal</FormLabel>
            <Select
              placeholder="Select Goal"
              name="fitness_goal"
              value={formData?.fitness_goal}
              onChange={handleInputChange}
            >
              <option value="athletic">Athletic</option>
              <option value="bodybuilding">Body building</option>
              <option value="fitness">Fitness</option>
            </Select>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormControl isInvalid={errors.health_issue}>
              <FormLabel>Health Issue</FormLabel>
              <Input
                placeholder="Any Health Issues"
                type="text"
                name="health_issue"
                value={formData?.health_issue}
                onChange={handleInputChange}
              />
              {errors.health_issue && (
                <FormErrorMessage>{errors.health_issue}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormControl isInvalid={errors.emergency_Contact_Name}>
              <FormLabel>Emergency contact name</FormLabel>
              <Input
                placeholder="Enter emergency contact person"
                type="text"
                name="emergency_Contact_Name"
                value={formData?.emergency_Contact_Name}
                onChange={handleInputChange}
              />
              {errors.emergency_Contact_Name && (
                <FormErrorMessage>
                  {errors.emergency_Contact_Name}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormControl isInvalid={errors.emergency_Contact_Number}>
              <FormLabel>Emergency contact number</FormLabel>
              <Input
                type="number"
                placeholder="Enter emergency contact phone"
                name="emergency_Contact_Number"
                value={formData?.emergency_Contact_Number}
                onChange={handleInputChange}
              />
              {errors.emergency_Contact_Number && (
                <FormErrorMessage>
                  {errors.emergency_Contact_Number}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormControl isInvalid={errors.start_date}>
              <FormLabel>Membership Start date</FormLabel>
              <Input
                placeholder="Enter Membership start date"
                type="date"
                name="start_date"
                value={formData?.start_date}
                onChange={handleInputChange}
              />
              {errors.start_date && (
                <FormErrorMessage>{errors.start_date}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormControl isInvalid={errors.end_date}>
              <FormLabel>Membership End Date</FormLabel>
              <Input
                type="date"
                name="end_date"
                value={formData?.end_date}
                onChange={handleInputChange}
              />
              {errors.end_date && (
                <FormErrorMessage>{errors.end_date}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormControl isInvalid={errors.amount}>
              <FormLabel>Membership Cost</FormLabel>
              <Input
                placeholder="Enter membership cost"
                type="number"
                name="amount"
                value={formData?.amount}
                onChange={handleInputChange}
              />
              {errors.amount && (
                <FormErrorMessage>{errors.amount}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormLabel>Balance Pay Date</FormLabel>
            <Input
              placeholder="Enter Balance Pay Date"
              type="date"
              name="balance_pay_date"
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormControl isInvalid={errors.mode_of_payment}>
              <FormLabel>Mode of payment</FormLabel>
              <Select
                placeholder="Select option"
                name="mode_of_payment"
                value={formData?.mode_of_payment}
                onChange={handleInputChange}
              >
                <option value="UPI">UPI</option>
                <option value="Online">Online</option>
                <option value="Cash">Cash</option>
                <option value="Card">Card</option>
                <option value="Cheque">Cheque</option>
              </Select>
              {errors.mode_of_payment && (
                <FormErrorMessage>{errors.mode_of_payment}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormLabel>Pending Amount</FormLabel>
            <Input
              placeholder="Enter pending amount"
              type="number"
              name="pending_amount"
              value={formData?.pending_amount}
              onChange={handlePendingAmountChange}
            />
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
            <FormLabel>Paid Amount</FormLabel>
            <Input
              placeholder="Enter amount paid"
              type="number"
              name="paid_amount"
              isDisabled
              value={formData?.paid_amount}
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 6 }} p="10px">
            <FormLabel>Remark</FormLabel>
            <Input
              placeholder="Enter remark"
              type="text"
              name="remark"
              value={formData?.remark || ""}
              onChange={handleInputChange}
            />
          </GridItem>
        </Grid>

        <Button
          colorScheme="red"
          m="20px"
          mt={0}
          type="submit"
          isLoading={loading}
          leftIcon={<PlusSquareIcon />}
        >
          Submit
        </Button>

        <Button
          colorScheme="gray"
          mb="20px"
          mt={0}
          onClick={() => navigate("/dashboard")}
        >
          Cancel
        </Button>
      </Container>
    </Box>
  );
}
