import React, { useState, useEffect } from "react";
import { Flex, Input, Box } from "@chakra-ui/react";
import BASE_URL from "../utils/BaseURL";
import axios from "axios";

const TrainerSelectMarkBar = ({ setMarkTrainer }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchBar, setSearchBar] = useState("");
  const [filteredtrainers, setfilteredtrainers] = useState([]);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    const GetFilteredtrainers = () => {
      axios
        .get(`${BASE_URL}/api/trainers/searchTrainer?search=${searchQuery}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setfilteredtrainers(res?.data?.trainers);
        })
        .catch((err) => console.log(err))
        .finally(() => setloading(false));
    };
    const Timer = setTimeout(() => {
      if (searchQuery !== "") {
        GetFilteredtrainers();
      }
    }, 500);
    return () => {
      setfilteredtrainers([]);
      clearTimeout(Timer);
    };
  }, [searchQuery]);

  const SetMark = (trainer) => {
    setfilteredtrainers([]);
    setSearchBar(
      `${trainer.full_name} - email: ${trainer.email} - phone: ${trainer.contact_number}`
    );
    setMarkTrainer(trainer);
  };

  const liststyles = {
    padding: "6px 12px",
    border: "0.1px solid pink",
    borderRadius: "2px",
    cursor: "pointer",
  };
  const UlStyles = {
    cursor: "pointer",
    position: "absolute",
    background: "white",
    zIndex: "5",
    width: "100%",
    listStyle: "none",
    maxHeight: "500px",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  const closedropdown = (e) => {
    if (!e.target.matches("[data-dropdown]")) {
      setfilteredtrainers([]);
    }
  };
  return (
    <Flex width={"100%"} onClick={closedropdown} bg="white">
      <Box width={"100%"} style={{ position: "relative" }}>
        <Input
          isDisabled={loading}
          type="text"
          value={searchBar}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setSearchBar(e.target.value);
          }}
          placeholder="Search for a A Trainer..."
        />

        {filteredtrainers.length > 0 && (
          <ul style={UlStyles} data-dropdown>
            {filteredtrainers.map((trainer, index) => (
              <li
                title="Click to Select this Trainer"
                onClick={() => SetMark(trainer)}
                style={liststyles}
                key={index}
              >
                {`${trainer.full_name} - email: ${trainer.email} - phone: ${trainer.contact_number}`}
              </li>
            ))}
          </ul>
        )}
      </Box>
    </Flex>
  );
};

export default TrainerSelectMarkBar;
