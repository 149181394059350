import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Heading,
  Input,
  Box,
  Flex,
  Tag,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";

export default function Login() {
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const navigate = useNavigate();

  const loginButtonHandler = (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    let body = { email: enteredEmail, password: enteredPassword };

    axios
      .post(`${BASE_URL}/api/admin/login`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("Email", enteredEmail);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("token", res?.data?.token);
          Swal.fire({
            icon: "success",
            title: "Login Successful",
          }).then(() => navigate("/dashboard"));
        }
      })
      .catch((err) => {
        // console.log(err);
        Swal.fire({
          icon: "error",
          title: err?.response?.data?.msg || "Something went wrong",
        });
      });
  };

  return (
    <>
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        backgroundImage="url(hype-bg.jpg)"
        backgroundSize="cover"
        zIndex={-1}
      />
      <Center mt="40">
        <Card bg="red.600" width="400px" align="center">
          <CardHeader>
            <Heading size="lg" style={{ color: "white" }}>
              Management Login
            </Heading>
          </CardHeader>
          <CardBody>
            <Input
              mb="5"
              bg="white"
              type="email"
              name="email"
              placeholder="Enter Email ID"
              ref={emailInputRef}
              required
            />
            <Input
              mb="5"
              bg="white"
              type="password"
              name="password"
              placeholder="Enter Password"
              ref={passwordInputRef}
              required
            />
            <Flex p="0" width={"100%"} justifyContent={"flex-end"}>
              <Tag
                cursor={"pointer"}
                m="0"
                p="0"
                onClick={() => navigate("/reset-pass-email")}
                variant={"unstyled"}
                color={"white"}
              >
                Forgot Password
              </Tag>
            </Flex>

            <Center>
              <Button onClick={loginButtonHandler}>Login</Button>
            </Center>
          </CardBody>
        </Card>
      </Center>
    </>
  );
}
