import {
  Container,
  FormLabel,
  Select,
  Button,
  Grid,
  GridItem,
  Text,
  FormControl,
  FormErrorMessage,
  Box,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import UserSelectMarkBar from "../components/UserSelectMarkBar";
import TrainerSelectMarkBar from "../components/TrainerSelectMarkBar";
import AttendanceHeader from "../components/AttendanceHeader";

const initialFormData = {
  trainerId: "",
  memberId: "",
  ratings: "",
  comment: "",
};

export default function AddReview() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  // const [trainers, setTrainers] = useState([]);
  // const [members, setMembers] = useState([]);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { trainerId, memberId, ratings, comment } = formData;

    const requiredFields = { trainerId, memberId, ratings, comment };
    const missingFields = Object.entries(requiredFields)
      .filter(([key, value]) => !value)
      .map(([key]) => key);

    if (missingFields.length > 0) {
      setErrors(
        missingFields.reduce((acc, field) => ({ ...acc, [field]: true }), {})
      );
      setLoading(false);
      return Swal.fire({
        icon: "warning",
        title: "Please provide all required fields.",
        text: `Missing fields: ${missingFields.join(", ")}`,
      });
    }

    try {
      const response = await fetch(`${BASE_URL}/api/reviews/addNewReview`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setLoading(false);
        setFormData(initialFormData);
        navigate("/attendance");
        Swal.fire({
          icon: "success",
          title: "New Ratings Added",
        });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "400 Error Bad Request",
        });
      }
    } catch (error) {
      console.error("Error adding Rating:", error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Server Error",
      });
    }
  };

  return (
    <>
      <AttendanceHeader />

      <Box mx={"10px"}>
        <Text mt="10px" mb="2" fontSize="1.5rem">
          Enter Review :
        </Text>
        <Container
          as="form"
          maxW="container"
          border="0.4px solid"
          borderRadius="5px"
          mb="10"
          onSubmit={handleSubmit}
        >
          <Grid templateColumns="repeat(6, 1fr)" p="10px">
            <GridItem colSpan={{ base: 6, md: 3 }} p="10px">
              <FormControl isInvalid={errors.trainerId}>
                <FormLabel>Trainer</FormLabel>
                <TrainerSelectMarkBar
                  setMarkTrainer={(trainer) =>
                    setFormData((prev) => ({ ...prev, trainerId: trainer._id }))
                  }
                />
                {errors.trainerId && (
                  <FormErrorMessage>Trainer is required.</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 6, md: 3 }} p="10px">
              <FormControl isInvalid={errors.memberId}>
                <FormLabel>Member</FormLabel>
                <UserSelectMarkBar
                  setMarkUser={(user) =>
                    setFormData((prev) => ({ ...prev, memberId: user._id }))
                  }
                />
                {errors.memberId && (
                  <FormErrorMessage>Member is required.</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 6, md: 6 }} p="10px">
              <FormControl isInvalid={errors.ratings}>
                <FormLabel>Rating</FormLabel>
                <Select
                  name="ratings"
                  value={formData.ratings}
                  onChange={handleInputChange}
                >
                  <option value="">Select Rating</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Select>
                {errors.ratings && (
                  <FormErrorMessage>Rating is required.</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 6, md: 6 }} p="10px">
              <FormControl isInvalid={errors.comment}>
                <FormLabel>Comment</FormLabel>
                <Textarea
                  background={"white"}
                  placeholder="Enter Comment"
                  name="comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                />
                {errors.comment && (
                  <FormErrorMessage>Comment is required.</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
          </Grid>

          <Button
            colorScheme="red"
            m="20px"
            mt={0}
            type="submit"
            isLoading={loading}
          >
            Submit
          </Button>

          <Button
            colorScheme="gray"
            mb="20px"
            mt={0}
            onClick={() => navigate("/attendance")}
          >
            Cancel
          </Button>
        </Container>
      </Box>
    </>
  );
}
