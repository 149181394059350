import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  Text,
  Center,
  Flex,
} from "@chakra-ui/react";
import BASE_URL from "../utils/BaseURL";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/reviews/getAllReviews`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();

        const groupedReviews = data.reviews.reduce((acc, review) => {
          const { trainerId } = review;
          if (!acc[trainerId._id]) {
            acc[trainerId._id] = {
              ...trainerId,
              ratings: [],
              averageRating: 0,
            };
          }
          acc[trainerId._id].ratings.push(review.ratings);
          return acc;
        }, {});

        Object.keys(groupedReviews).forEach((key) => {
          const totalRatings = groupedReviews[key].ratings.reduce(
            (sum, rating) => sum + rating,
            0
          );
          groupedReviews[key].averageRating = (
            totalRatings / groupedReviews[key].ratings.length
          ).toFixed(2);
        });

        setReviews(Object.values(groupedReviews));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) {
    return (
      <Center mt="20">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box mx={"10px"} mt={"10px"}>
      <Flex justifyContent={"space-between"} my={"2"} mx={"1"}>
        <Text fontSize="1.5rem" mb="10px">
          Reviews:
        </Text>
      </Flex>
      <Container maxW="container.xl" p="0">
        <TableContainer border="0.5px solid" borderRadius="0px">
          <Table variant="striped" colorScheme="red" size="sm">
            <TableCaption>All Registered Reviews</TableCaption>
            <Thead>
              <Tr>
                <Th>S.no</Th>
                <Th>Trainer</Th>
                <Th>Average Rating</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reviews.map((trainer, ind) => (
                <Tr key={trainer._id}>
                  <Td>{ind + 1}</Td>
                  <Td>{trainer.full_name}</Td>
                  <Td>{trainer.averageRating}</Td>
                  <Td>
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() =>
                        navigate(`/dashboard/reviews/${trainer._id}`)
                      }
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default Reviews;
