import {
  Center,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import axios from "axios";
import EditDate, { convertDate, getCurrentDateRev } from "../utils/EditDate";
import PaymentFilters from "../components/PaymentFilters";

const Payments = () => {
  const [Payments, setPayments] = useState([]);

  useEffect(() => {
    let makereq = localStorage.getItem("isLoggedIn");
    if (makereq) {
      GetAllPayments();
    }
    return () => (makereq = false);
  }, []);

  const GetAllPayments = (current) => {
    if (!current) {
      current = getCurrentDateRev();
    }

    axios
      .get(`${BASE_URL}/api/payments/getAllPayments`, {
        params: {
          createdAt: current,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let filteredPayments = res?.data?.Payments?.reverse();
        setPayments(filteredPayments);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "500 Server Error",
          text: err,
        });
      });
  };

  let totalAmount = 0;

  return (
    <Card>
      <CardHeader>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Heading size={{ base: "sm", md: "md" }} m="3" textAlign="center">
            Monthly Payments
          </Heading>
          <PaymentFilters
            GetAllPayments={GetAllPayments}
            setPayments={setPayments}
            Payments={Payments}
          />
        </Stack>
      </CardHeader>
      <CardBody>
        <TableContainer
          border="0.5px solid"
          p="2"
          mx={{ md: 5 }}
          borderRadius="5px"
        >
          <Table
            variant="striped"
            colorScheme="red"
            size="sm"
            style={{ overflowY: "scroll" }}
          >
            <Thead>
              <Tr>
                <Th>S.No.</Th>
                <Th>User ID</Th>
                <Th>Contact</Th>
                <Th style={{ whiteSpace: "pre" }}>Date</Th>
                <Th style={{ whiteSpace: "pre" }}>{"Membership\nCost"}</Th>
                <Th style={{ whiteSpace: "pre" }}>{"Payment\nAmount"}</Th>
                <Th style={{ whiteSpace: "pre" }}>{"Pending\nAmount"}</Th>
                <Th style={{ whiteSpace: "pre" }}>{"Paid\nAmount"}</Th>
                <Th>Total Revenue</Th>
                <Th>Start Date</Th>
                <Th>End Date</Th>
                <Th>Mode of Payment</Th>
                <Th>Remark</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Payments?.length > 0 ? (
                Payments?.map((payment, index) => {
                  return payment?.userID?.full_name ? (
                    <Tr key={index + "payment" + payment?.userID}>
                      <Td>{index + 1}</Td>
                      <Td>{payment?.userID?.full_name}</Td>
                      <Td>+91 {payment?.contact}</Td>
                      <Td>{EditDate(convertDate(payment?.createdAt))}</Td>
                      <Td>{payment.paid_amount + +payment.pending_amount}</Td>
                      <Td style={{ fontWeight: 600 }}>
                        {payment?.payment_amount}
                      </Td>
                      <Td>{payment?.pending_amount}</Td>
                      <Td>{payment?.paid_amount}</Td>
                      <Td style={{ fontWeight: 600 }}>
                        {(totalAmount += +payment.payment_amount)}
                      </Td>
                      <Td>{EditDate(payment?.membership_start_date)}</Td>
                      <Td>{EditDate(payment?.membership_end_date)}</Td>
                      <Td>{payment?.mode_of_payment}</Td>
                      <Td>{payment?.remark}</Td>
                    </Tr>
                  ) : null;
                })
              ) : (
                <Tr>
                  <Td colSpan={12}>
                    <Center m="5px">No Payments Available</Center>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default Payments;
