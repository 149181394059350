import {
  Button,
  Input,
  GridItem,
  FormLabel,
  Select,
  Grid,
  FormControl,
  FormErrorMessage,
  Heading,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialog,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Center,
  Text,
} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import EditDate, { convertDate, splitReverseByDash } from "../utils/EditDate";
import axios from "axios";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";

const EditUserModal = ({
  isOpen,
  OneUser,
  setOneUser,
  extendMembership,
  pauseMembership,
  resumeMembership,
  handleInputChange,
  GetUsers,
  IsEdit,
  setIsEdit,
  onClose,
}) => {
  const cancelRef = useRef();
  const [EditLoading, setEditLoading] = useState(false);
  const [isDeleteLoading, setisDeleteLoading] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    setIsPaused(OneUser.isPaused === "true");
  }, [OneUser]);

  const {
    isOpen: isTOpen,
    onOpen: onTOpen,
    onClose: onTClose,
  } = useDisclosure();
  const isError1 = false;
  const isError2 = false;

  const ChangeUserStatus = (id, status) => {
    setEditLoading(true);
    let data = { status };

    axios
      .post(`${BASE_URL}/api/users/update-user/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Changed User Status Successfully",
        });
        setOneUser(res?.data?.UpdatedUser);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      })
      .finally(() => {
        onTClose();
        onClose();
        GetUsers(1);
        setEditLoading(false);
        setIsEdit(false);
      });
  };
  const DeleteUserForever = (id) => {
    setisDeleteLoading(true);
    axios
      .delete(`${BASE_URL}/api/users/delete-user/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Deleted User Successfully",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      })
      .finally(() => {
        onTClose();
        onClose();
        GetUsers(1);
        setisDeleteLoading(false);
        setIsEdit(false);
      });
  };

  const SaveUser = (id, data) => {
    setEditLoading(true);
    data = {
      ...data,
      start_date: splitReverseByDash(data.start_date),
      end_date: splitReverseByDash(data.end_date),
      balance_pay_date: splitReverseByDash(data.balance_pay_date),
    };
    data.pending_amount = +data.amount;

    axios
      .post(`${BASE_URL}/api/users/update-user/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Saved data successfully",
        });
        setOneUser(res?.data?.UpdatedUser);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      })
      .finally(() => {
        onClose();
        GetUsers(1);
        setEditLoading(false);
        setIsEdit(false);
      });
  };
  // const DeletePaymentAndRevert = (paymentID, userID) => {
  //   axios
  //     .delete(`${BASE_URL}/api/payments/delete-payment`, {
  //       params: {
  //         paymentID,
  //         userID,
  //       },
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((res) => {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Deleted Payment successfully",
  //       });
  //       setOneUser(res?.data?.UpdatedUser);
  //     })
  //     .catch((err) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Something went wrong",
  //       });
  //     })
  //     .finally(() => {
  //       setEditLoading(false);
  //       setIsEdit(false);
  //       onClose();
  //     });
  // };

  


  const handleMembershipCostChange = (value) => {
    setOneUser((prev) => ({
      ...prev,
      amount: value,
    }));
  };
  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setIsEdit(false);
          onClose();
        }}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>User : {OneUser?.full_name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {OneUser.status === "terminated" && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Terminated!</AlertTitle>
                <AlertDescription>
                  This gym member was terminated by admin.
                </AlertDescription>
              </Alert>
            )}
            {OneUser.isPaused === "true" && (
              <Alert status="warning">
                <AlertIcon />
                <AlertTitle>Paused!</AlertTitle>
                <AlertDescription>
                  This gym member is currently paused.
                </AlertDescription>
              </Alert>
            )}
            <Grid templateColumns="repeat(6, 1fr)">
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormLabel>Enter Full Name</FormLabel>
                <Input
                  isDisabled={!IsEdit}
                  placeholder="Enter Full Name"
                  type="text"
                  name="full_name"
                  value={OneUser.full_name}
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormLabel>Membership ID</FormLabel>
                <Input
                  isDisabled={!IsEdit}
                  placeholder="Enter Membership ID"
                  type="text"
                  name="membershipID"
                  value={OneUser.membershipID}
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormControl isInvalid={isError1}>
                  <FormLabel>Contact number</FormLabel>
                  <Input
                    isDisabled={!IsEdit}
                    placeholder="Enter Contact Number"
                    type="number"
                    name="contact"
                    value={OneUser.contact}
                    onChange={handleInputChange}
                  />
                  {isError1 && (
                    <FormErrorMessage>
                      Enter 10 digit phone number
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormLabel>Email ID</FormLabel>
                <Input
                  isDisabled={!IsEdit}
                  placeholder="Enter Email"
                  type="email"
                  name="email"
                  value={OneUser.email}
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormLabel>Goal</FormLabel>
                <Select
                  isDisabled={!IsEdit}
                  placeholder="Select Goal"
                  name="fitness_goal"
                  value={OneUser.fitness_goal}
                  onChange={handleInputChange}
                >
                  <option value="athletic">Athletic</option>
                  <option value="bodybuilding">Body building</option>
                  <option value="fitness">Fitness</option>
                </Select>
              </GridItem>{" "}
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormLabel>Health Issue</FormLabel>
                <Input
                  isDisabled={!IsEdit}
                  placeholder="Any Health Issues"
                  type="text"
                  name="health_issue"
                  value={OneUser.health_issue}
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormLabel>Emergency contact name</FormLabel>
                <Input
                  isDisabled={!IsEdit}
                  placeholder="Enter emergency contact person"
                  type="text"
                  name="emergency_Contact_Name"
                  value={OneUser.emergency_Contact_Name}
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormLabel>Membership Start date</FormLabel>
                {!IsEdit ? (
                  <Heading size={"sm"}>
                    {splitReverseByDash(EditDate(OneUser.start_date))}
                  </Heading>
                ) : (
                  <Input
                    type="date"
                    name="start_date"
                    value={OneUser.start_date}
                    onChange={handleInputChange}
                  />
                )}
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormLabel>Membership End Date</FormLabel>
                {!IsEdit ? (
                  <Heading size={"sm"}>
                    {splitReverseByDash(EditDate(OneUser.end_date))}
                  </Heading>
                ) : (
                  <Input
                    type="date"
                    name="end_date"
                    value={OneUser.end_date}
                    onChange={handleInputChange}
                  />
                )}
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormControl isInvalid={isError2}>
                  <FormLabel>Emergency contact number</FormLabel>
                  <Input
                    isDisabled={!IsEdit}
                    type="number"
                    placeholder="Enter emergency contact phone"
                    name="emergency_Contact_Number"
                    value={OneUser.emergency_Contact_Number}
                    onChange={handleInputChange}
                  />
                  {isError2 && (
                    <FormErrorMessage>
                      Enter 10 digit phone number
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="5px">
                <FormLabel>Membership Cost</FormLabel>
                <Input
                  isDisabled={!IsEdit || OneUser.pending_amount !== 0}
                  placeholder="Enter membership cost"
                  type="number"
                  name="amount"
                  value={OneUser.amount}
                  onChange={(e) => handleMembershipCostChange(e.target.value)}
                />
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 1 }} p="5px">
                <FormLabel>Pending Amount</FormLabel>
                <Input
                  isDisabled={true}
                  placeholder="Enter pending amount"
                  type="number"
                  name="pending_amount"
                  value={OneUser.pending_amount}
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 1 }} p="5px">
                <FormLabel>Paid Amount</FormLabel>
                <Input
                  isDisabled={true}
                  placeholder="Enter amount paid"
                  type="number"
                  name="paid_amount"
                  value={OneUser.paid_amount}
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 6 }} p="5px">
                <Text align={"right"} fontSize={"12px"}>
                  Note: Membership cost cannot be changed until the pending
                  amount is Zero(0)
                </Text>
                <FormLabel>Remark</FormLabel>
                <Input
                  isDisabled={!IsEdit}
                  placeholder="Enter remark"
                  type="text"
                  name="remark"
                  value={OneUser.remark || ""}
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem as="div" colSpan={{ base: 6, md: 6 }} p="5px">
                <Flex justifyContent={"end"} gap={"2"}>

                
                  {OneUser.status !== "terminated" && IsEdit ? (
                    <>
                      <Button
                        colorScheme="green"
                        onClick={() => SaveUser(OneUser._id, OneUser)}
                        isLoading={EditLoading}
                      >
                        Save
                      </Button>
                      <Button
                        colorScheme="blue"
                        onClick={() => setIsEdit(false)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : OneUser.status !== "terminated" && !IsEdit ? (
                    <>
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          onClose();
                          extendMembership();
                        }}
                      >
                        Create Payment
                      </Button>
                      <Button
                        colorScheme="yellow"
                        isDisabled={splitReverseByDash(OneUser.start_date) == OneUser.membership_pause_start_date}
                        onClick={() => {
                          onClose();
                          pauseMembership();
                          
                        }}
                      >
                        Pause
                      </Button>
                      <Button
                        colorScheme="orange"
                        isDisabled={OneUser.isPaused == false}
                        onClick={() => {
                          onClose();
                          resumeMembership();
                        }}
                      >
                       Resume
                      </Button>
                      <Button
                        colorScheme="purple"
                        onClick={() => setIsEdit(true)}
                      >
                        Edit
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {OneUser.status === "terminated" ? (
                    <Flex gap={2}>
                      <Button
                        colorScheme="red"
                        onClick={() => DeleteUserForever(OneUser._id)}
                        isLoading={isDeleteLoading}
                      >
                        Delete
                      </Button>
                      <Button
                        colorScheme="green"
                        onClick={() => ChangeUserStatus(OneUser._id, "active")}
                        isLoading={EditLoading}
                      >
                        Activate
                      </Button>
                    </Flex>
                  ) : (
                    <Button
                      colorScheme="red"
                      isLoading={EditLoading}
                      onClick={() => {
                        onTOpen();
                      }}
                    >
                      Terminate
                    </Button>
                  )}

                 

                </Flex>
              </GridItem>
            </Grid>
            <Text fontWeight={"600"}>Payments History</Text>
            <TableContainer
              border="0.5px solid"
              p="2"
              my={2}
              borderRadius="5px"
            >
              <Table
                variant="striped"
                colorScheme="red"
                size="sm"
                style={{ overflowY: "scroll" }}
              >
                <Thead>
                  <Tr>
                    <Th>S.No.</Th>
                    <Th style={{ whiteSpace: "pre" }}>{"Payment\nDate"}</Th>
                    <Th style={{ whiteSpace: "pre" }}>{"Payment\nAmount"}</Th>
                    <Th style={{ whiteSpace: "pre" }}>{"Paid\nAmount"}</Th>
                    <Th style={{ whiteSpace: "pre" }}>{"Pending\nAmount"}</Th>
                    <Th style={{ whiteSpace: "pre" }}>{"Membership\nCost"}</Th>
                    <Th style={{ whiteSpace: "pre" }}>{"Start\nDate"}</Th>
                    <Th style={{ whiteSpace: "pre" }}>{"End\nDate"}</Th>
                    <Th style={{ whiteSpace: "pre" }}>Mode</Th>
                    <Th style={{ whiteSpace: "pre" }}>Remark</Th>
                    {/* <Th style={{ whiteSpace: "pre" }}>Actions</Th> */}
                  </Tr>
                </Thead>
                <Tbody>
                  {OneUser?.payments?.length > 0 ? (
                    OneUser?.payments?.map((payment, index) => {
                      return (
                        <Tr key={index + "paymentx" + payment?.userID}>
                          <Td>{index + 1}</Td>
                          <Td>{EditDate(convertDate(payment?.createdAt))}</Td>
                          <Td fontWeight={600}>{payment?.payment_amount}</Td>
                          <Td>{payment?.paid_amount}</Td>
                          <Td>{payment?.pending_amount}</Td>
                          <Td fontWeight={600}>{payment?.membership_cost}</Td>
                          <Td>{EditDate(payment?.membership_start_date)}</Td>
                          <Td>{EditDate(payment?.membership_end_date)}</Td>
                          <Td>{payment?.mode_of_payment}</Td>
                          <Td>{payment?.remark}</Td>
                          {/* <Td>
                            {OneUser.payments.length - 1 === index ? (
                              <Button
                                size={"xs"}
                                colorScheme="red"
                                onClick={() =>
                                  DeletePaymentAndRevert(
                                    payment._id,
                                    OneUser._id
                                  )
                                }
                              >
                                Delete
                              </Button>
                            ) : (
                              "NA"
                            )}
                          </Td> */}
                        </Tr>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td colSpan={10}>
                        <Center m="5px">No Payments Available</Center>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>

      <AlertDialog
        isCentered
        isOpen={isTOpen}
        leastDestructiveRef={cancelRef}
        onClose={onTClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure?
            </AlertDialogHeader>

            <AlertDialogBody>You are Terminating a gym member.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onTClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  ChangeUserStatus(OneUser._id, "terminated");
                  onTClose();
                }}
                ml={3}
              >
                Terminate
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EditUserModal;
