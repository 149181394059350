import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Heading,
  Input,
  Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import logo from "../assets/hypecircle.jpg";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import axios from "axios";

export default function AttendanceLogin() {
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const navigate = useNavigate();

  const loginButtonHandler = (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    let body = { email: enteredEmail, attendance_pass: enteredPassword };
    axios
      .post(`${BASE_URL}/api/admin/attendanceLogin`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("Email", enteredEmail);
          localStorage.setItem("token", res?.data?.token);
          localStorage.setItem("AttendanceLogin", true);
          localStorage.setItem("EmployeeType", res?.data?.type);
          Swal.fire({
            icon: "success",
            title: "Login Successful",
          }).then(() => navigate(0));
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err?.response?.data?.msg || "Something went wrong",
        });
      });
  };

  return (
    <>
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        backgroundImage="url(hype-bg.jpg)"
        backgroundSize="cover"
        zIndex={-1}
      />

      <Center mt="10">
        <Card bg="red.600" width="400px">
          <CardHeader>
            <img
              src={logo}
              width="50%"
              style={{ borderRadius: "100%", margin: "auto" }}
              alt="Hype Gym"
            />
            <br />
            <Heading size="lg" color="white" style={{ textAlign: "center" }}>
              Attendance Login
            </Heading>
          </CardHeader>
          <CardBody>
            <Input
              mb="5"
              bg="white"
              type="email"
              name="email"
              placeholder="Enter Email ID"
              ref={emailInputRef}
              required
            />
            <Input
              mb="5"
              bg="white"
              type="password"
              name="password"
              placeholder="Enter Password"
              ref={passwordInputRef}
              required
            />
            <Center>
              <Button onClick={loginButtonHandler}>Login</Button>
            </Center>
          </CardBody>
        </Card>
      </Center>
    </>
  );
}
