import { Center, Image, Text } from "@chakra-ui/react";
import React from "react";
import Logo from "../assets/hypecircle.jpg";

function SplashScreen() {
  return (
    <>
      <Center>
        <Image
          src={Logo}
          mt="130px"
          borderRadius="50%"
          boxSize="350px"
          objectFit="cover"
        />
      </Center>
      <Center fontSize={{ base: "4em", md: "6.5em" }}>
        <Text fontWeight={800}> Hype Gym</Text>
      </Center>
    </>
  );
}

export default SplashScreen;
