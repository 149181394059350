import React from "react";
import { MenuItem, MenuList, Menu, MenuButton } from "@chakra-ui/react";
import { Button, Flex, Heading, Image, Stack } from "@chakra-ui/react";
import logo from "../assets/hypecircle.jpg";

import {
  SettingsIcon,
  ChevronDownIcon,
  EditIcon,
  AddIcon,
  ChatIcon,
} from "@chakra-ui/icons";
import { NavLink, useNavigate } from "react-router-dom";

const AttendanceHeader = () => {
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/attendance");
  };
  return (
    <Flex
      width="100%"
      as="nav"
      justifyContent="space-between"
      alignItems="center"
      bg="red.100"
      px={"20px"}
    >
      <Image
        width={"65px"}
        style={{ borderRadius: "50%" }}
        src={logo}
        alt="Hype Logo"
        mx={{ base: "5px", md: "30px" }}
      />
      <Heading>Attendance Panel</Heading>

      <Stack gap="10px" direction={{ base: "column", md: "row" }}>
        <Button
          colorScheme="green"
          leftIcon={<ChatIcon></ChatIcon>}
          onClick={() => navigate("/attendance/reviews/add")}
        >
          Review
        </Button>
        <Button
          colorScheme="red"
          leftIcon={<AddIcon></AddIcon>}
          onClick={() => navigate("/attendance/complaints/add")}
        >
          Complaint
        </Button>
        <Menu>
          <MenuButton
            as={Button}
            aria-label="Options"
            rightIcon={<ChevronDownIcon />}
          >
            Hype
          </MenuButton>
          <MenuList>
            <MenuItem icon={<EditIcon />}>Profile</MenuItem>
            <MenuItem
              as={NavLink}
              to="/attendance/changepassword"
              icon={<SettingsIcon />}
            >
              Change Password
            </MenuItem>
          </MenuList>
        </Menu>
        <Button onClick={handleLogout} colorScheme="teal">
          Logout
        </Button>
      </Stack>
    </Flex>
  );
};

export default AttendanceHeader;
