import {
  Container,
  FormLabel,
  Input,
  Button,
  Grid,
  GridItem,
  Text,
  FormControl,
  FormErrorMessage,
  Box,
} from "@chakra-ui/react";

import { useState } from "react";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PlusSquareIcon } from "@chakra-ui/icons";
import uploadFileToS3 from '../utils/AWS-Upload';

function ReturnCurrDate() {
  const date = new Date();
  let dat = new Date().getDate();
  let month = date.getMonth() + 1;
  if (dat < 10) dat = `0${dat}`;
  if (month < 10) month = `0${month}`;
  let str = `${date.getFullYear()}-${month}-${dat}`;
  return str;
}

let initial = {
  full_name: "",
  date_of_joining: ReturnCurrDate(),
  address: "",
  contact_number: "",
  email: "",
  image: "",
};

export default function AddTrainer() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initial);
  const [imageFile, setImageFile] = useState(null);


  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === 'image') {
      setImageFile(files[0]);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const isError = formData?.contact_number.length !== 10;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (isError) {
      setLoading(false);
      return Swal.fire({
        icon: "warning",
        title: "Please Write 10 digit phone number",
        text: "Notification sent to user",
      });
    }

    if (!imageFile) {
      setLoading(false);
      return Swal.fire({
        icon: "warning",
        title: "Please upload an image",
        text: "Image is required",
      });
    }

    try {
      const uploadResult = await uploadFileToS3(imageFile);
      const imageUrl = uploadResult.Location;

      const trainerData = { ...formData, image: imageUrl };

      let req = await fetch(`${BASE_URL}/api/trainers/addNewTrainer`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(trainerData),
      });

      if (req.ok) {
        setLoading(false);
        setFormData(initial);
        setImageFile(null);
        navigate("/dashboard");
        Swal.fire({
          icon: "success",
          title: "New Trainer Added",
          text: "Notification sent to user",
        });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "400 Error Bad Request",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Image Upload Failed",
        text: error.message,
      });
    }
  };
  return (
    <Box mx={"10px"}>
      <Text mt="10px" mb="2" fontSize="1.5rem">
        Enter details of Trainer:
      </Text>
      <Container
        as="form"
        maxW="container"
        border="0.4px solid"
        borderRadius="5px"
        mb="10"
        onSubmit={handleSubmit}
      >
        <Grid templateColumns="repeat(6, 1fr)" p="10px">
          <GridItem as="div" colSpan={{ base: 6, md: 3 }} p="10px">
            <FormLabel>Enter Full Name</FormLabel>
            <Input
              placeholder="Enter Full Name"
              type="text"
              name="full_name"
              value={formData?.full_name}
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 3 }} p="10px">
            <FormLabel>Date of Joining</FormLabel>
            <Input
              placeholder="Enter Date of Joining"
              type="date"
              name="date_of_joining"
              value={formData?.date_of_joining}
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 6 }} p="10px">
            <FormLabel>Address</FormLabel>
            <Input
              placeholder="Enter Address"
              type="text"
              name="address"
              value={formData?.address}
              onChange={handleInputChange}
            />
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 3 }} p="10px">
            <FormControl isInvalid={isError}>
              <FormLabel>Contact Number</FormLabel>
              <Input
                placeholder="Enter Contact Number"
                type="number"
                name="contact_number"
                value={formData?.contact_number}
                onChange={handleInputChange}
              />
              {isError && (
                <FormErrorMessage>Enter 10 digit phone number</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem as="div" colSpan={{ base: 6, md: 3 }} p="10px">
            <FormLabel>Email (optional)</FormLabel>
            <Input
              placeholder="Enter Email"
              type="email"
              name="email"
              value={formData?.email}
              onChange={handleInputChange}
            />
          </GridItem>
           <GridItem as="div" colSpan={{ base: 6, md: 6 }} p="10px">
            <FormLabel>Image</FormLabel>
            <Input
              placeholder="Upload Image"
              type="file"
              name="image"
              onChange={handleInputChange}
            />
          </GridItem>
        </Grid>

        <Button
          colorScheme="red"
          m="20px"
          mt={0}
          type="submit"
          isLoading={loading}
          leftIcon={<PlusSquareIcon />}
        >
          Submit
        </Button>

        <Button
          colorScheme="gray"
          mb="20px"
          mt={0}
          onClick={() => navigate("/dashboard")}
        >
          Cancel
        </Button>
      </Container>
    </Box>
  );
}
