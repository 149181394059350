import React, { useState, useEffect } from "react";
import { Flex, Input, Box } from "@chakra-ui/react";
import BASE_URL from "../utils/BaseURL";
import axios from "axios";

const UserSelectMarkBar = ({ setMarkUser }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchBar, setSearchBar] = useState("");
  const [filteredUsers, setfilteredUsers] = useState([]);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    const GetFilteredUsers = () => {
      axios
        .get(`${BASE_URL}/api/users/searchUser?search=${searchQuery}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setfilteredUsers(res?.data?.Users);
        })
        .catch((err) => console.log(err))
        .finally(() => setloading(false));
    };
    const Timer = setTimeout(() => {
      if (searchQuery !== "") {
        GetFilteredUsers();
      }
    }, 500);
    return () => {
      setfilteredUsers([]);
      clearTimeout(Timer);
    };
  }, [searchQuery]);

  const SetMark = (user) => {
    setfilteredUsers([]);
    setSearchBar(
      `${user.full_name} - email: ${user.email} - phone: ${user.contact}`
    );
    setMarkUser(user);
  };

  const liststyles = {
    padding: "6px 12px",
    border: "0.1px solid pink",
    borderRadius: "2px",
    cursor: "pointer",
  };
  const UlStyles = {
    cursor: "pointer",
    position: "absolute",
    background: "white",
    zIndex: "5",
    width: "100%",
    listStyle: "none",
    maxHeight: "500px",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  const closedropdown = (e) => {
    if (!e.target.matches("[data-dropdown]")) {
      setfilteredUsers([]);
    }
  };
  return (
    <Flex width={"100%"} onClick={closedropdown} bg="white">
      <Box width={"100%"} style={{ position: "relative" }}>
        <Input
          isDisabled={loading}
          type="text"
          value={searchBar}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setSearchBar(e.target.value);
          }}
          placeholder="Search for a A User..."
        />

        {filteredUsers.length > 0 && (
          <ul style={UlStyles} data-dropdown>
            {filteredUsers.map((user, index) => (
              <li
                title="Click to Select this User"
                onClick={() => SetMark(user)}
                style={liststyles}
                key={index}
              >
                {`${user.full_name} - email: ${user.email} - phone: ${user.contact}`}
              </li>
            ))}
          </ul>
        )}
      </Box>
    </Flex>
  );
};

export default UserSelectMarkBar;
