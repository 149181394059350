import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";

const EnterEmail = () => {
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const handleSendLinkFunction = () => {
    if (!email) {
      return Swal.fire({
        icon: "warning",
        title: "Please Enter a valid Email",
      });
    }
    const body = {
      email,
    };
    axios
      .post(`${BASE_URL}/api/admin/send-reset-password-link`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        Swal.fire({
          icon: "success",
          title: "Email has been sent with the reset password link!",
        }).then(() => navigate("/"));
      })
      .catch((err) => {
        console.log(err);

        Swal.fire({
          icon: "error",
          title:
            err?.response?.data?.message ||
            err.message ||
            "Some Error Occurred",
        });
      });
  };

  return (
    <Box
      height={"100vh"}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Flex w={"30%"} gap={2} direction={"column"}>
        <Text align={"center"} fontSize={"20px"} fontWeight={"700"}>
          Reset your Password through email :-
        </Text>
        <FormLabel>Enter Email to send reset link</FormLabel>
        <InputGroup>
          <Input
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </InputGroup>

        <Button
          color={"white"}
          background={"red"}
          onClick={handleSendLinkFunction}
        >
          Submit
        </Button>
      </Flex>
    </Box>
  );
};

export default EnterEmail;
