// DollarIcon.js
import { createIcon } from '@chakra-ui/react';

const DollarIcon = createIcon({
  displayName: 'DollarIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M12 1C11.45 1 11 1.45 11 2V4.1C9.06 4.56 7.56 6.26 7.14 8.27C7.05 8.73 7.41 9.16 7.88 9.16H8.91C9.35 9.16 9.73 8.88 9.87 8.45C10.12 7.66 10.92 7.1 11.82 7.1C12.9 7.1 13.78 7.98 13.78 9.06C13.78 10.09 13.09 10.86 12.15 11.13L10.66 11.59C9.25 12.01 8.22 13.33 8.22 14.84V15.9C8.22 16.45 8.67 16.9 9.22 16.9H10.78C11.33 16.9 11.78 16.45 11.78 15.9V15.26C12.22 15.36 12.68 15.42 13.14 15.42C15.43 15.42 17.22 13.63 17.22 11.34C17.22 8.89 15.32 7 12.88 7H11.82C10.91 7 10.11 6.42 9.87 5.61C9.74 5.18 9.35 4.9 8.91 4.9H7.88C7.41 4.9 7.05 5.33 7.14 5.79C7.56 7.8 9.06 9.5 11 9.96V12C11 12.55 11.45 13 12 13C12.55 13 13 12.55 13 12V10.54C14.45 10.19 15.78 9.38 16.78 8.19C17.67 7.13 18.22 5.66 18.22 4C18.22 2.35 17.67 0.87 16.78 0C15.78 -1.2 14.45 -2.01 13 -2.36V0C13 0.55 12.55 1 12 1Z"
    />
  ),
});

export default DollarIcon;
