import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import AllUsersTab from "../components/AllUsersTab";
import ExpireList from "../components/ExpireList";
import ExpiredList from "../components/ExpiredList";
import SearchList from "../components/SearchList";
import PausedList from "../components/PausedList";

import ActiveList from "../components/ActiveList";

export default function MembersList({
  TotalMembers,
  Active,
  Expired,
  Expiring,
  Paused,
 
}) {
  return (
    <Tabs variant="enclosed">
      <TabList>
        <Tab>All</Tab>
        <Tab>Active</Tab>
        <Tab>Expiring</Tab>
        <Tab>Expired</Tab>
        <Tab>Paused</Tab>
        <Tab>Search </Tab>
      </TabList>

      <TabPanels>
        <AllUsersTab TotalMembers={TotalMembers} />
        <TabPanel>
          <ActiveList Active={Active} />
        </TabPanel>
        <TabPanel>
          <ExpireList Expiring={Expiring} />
        </TabPanel>
        <TabPanel>
          <ExpiredList Expired={Expired} />
        </TabPanel>
        <TabPanel>
          <PausedList Paused={Paused} />
        </TabPanel>
        <TabPanel>
          <SearchList />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
