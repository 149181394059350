import {
    Button, Card, CardBody, Center, Heading, Input,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../utils/BaseURL";
import axios from "axios"
import Swal from 'sweetalert2';


const ChangePassword = () => {

    const navigate = useNavigate();

    const oldPassInput = useRef();
    const newPassInput = useRef();
    const confirmPassInput = useRef();

    const changePassHandler = (event) => {
        event.preventDefault();
        const enteredOldPass = oldPassInput.current.value;
        const enteredNewPass = newPassInput.current.value;
        const enteredConfirmPass = confirmPassInput.current.value;
        if (enteredNewPass !== enteredConfirmPass) {
            return Swal.fire({
                icon: "error",
                title: "Passwords do not Match"
            })
        } else if (enteredOldPass === enteredNewPass) {
            return Swal.fire({
                icon: "error",
                text: "Old & New Password cannot be same."
            })
        }

        let body = {
            providedPass: enteredOldPass,
            newPass: enteredConfirmPass,
            email: localStorage.getItem("Email")
        }

        axios.patch(`${BASE_URL}/api/admin/changePassword`, body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        }).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Password Changed Successfully"
                }).then(() => navigate("/dashboard", { replace: true }))
            }
        }).catch((err) => {
            console.log(err)
            Swal.fire({
                icon: "error",
                text: err?.response?.data?.msg || "Something Went Wrong"
            })
        })
    };

    return (
        <>
            <Center p="5">
                <Card width="400px" border="0.2px solid maroon" p="3">
                    <Heading size="md" textAlign="center">
                        Change Password
                    </Heading>
                    <CardBody>
                        <Input
                            m="3"
                            placeholder="Enter your old password"
                            width="300px"
                            ref={oldPassInput}
                        />
                        <Input
                            m="3"
                            placeholder="Enter new password"
                            width="300px"
                            ref={newPassInput}
                        />
                        <Input
                            m="3"
                            placeholder="Confirm new password"
                            width="300px"
                            ref={confirmPassInput}
                        />
                    </CardBody>
                    <Button colorScheme="red" onClick={changePassHandler}>
                        Submit
                    </Button>
                </Card>
            </Center>
        </>
    );
};

export default ChangePassword;