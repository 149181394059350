import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  Text,
  Center,
  Flex,
} from "@chakra-ui/react";
import BASE_URL from "../utils/BaseURL";

const Complaints = () => {
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchComplaints = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/complaints/getAllComplaints`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();

        setComplaints(data.complaints);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching complaints:", error);
        setLoading(false);
      }
    };

    fetchComplaints();
  }, []);

  if (loading) {
    return (
      <Center mt="20">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box mx={"10px"} mt={"10px"}>
      <Flex justifyContent={"space-between"} my={"2"} mx={"1"}>
        <Text fontSize="1.5rem" mb="10px">
          List of Complaints:
        </Text>
      </Flex>
      <Container maxW="container.xl" p="0">
        <TableContainer border="0.5px solid" borderRadius="0px">
          <Table variant="striped" colorScheme="red" size="md">
            <TableCaption>All Registered Complaints</TableCaption>
            <Thead>
              <Tr>
                <Th>S.no</Th>
                <Th>Title</Th>
                <Th>Description</Th>
                <Th>Trainer</Th>
                <Th>Member</Th>
                <Th>Date</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {complaints.map((complaint, ind) => (
                <Tr key={complaint._id}>
                  <Td>{ind + 1}</Td>
                  <Td>{complaint.title}</Td>
                  <Td>{complaint.description}</Td>
                  <Td>{complaint.trainerId.full_name}</Td>
                  <Td>{complaint.memberId.full_name}</Td>
                  <Td>{new Date(complaint.createdAt).toLocaleDateString()}</Td>
                  <Td>
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() =>
                        navigate(`/dashboard/complaints/${complaint._id}`)
                      }
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default Complaints;
