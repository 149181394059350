function daysLeft(dateStr) {

    var currentDate = new Date();
    var parts = dateStr.split("/");
    var targetDate = new Date(parts[2], parts[1] - 1, parts[0]);

    if (targetDate < currentDate) {
        return -1;
    }

    var timeDiff = targetDate.getTime() - currentDate.getTime();
    var daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysLeft;
}
function hasDatePassed(dateString) {


    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const inputDate = new Date(year, month, day);
    const currentDate = new Date();

    return inputDate < currentDate
}


module.exports = { daysLeft, hasDatePassed };