import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Grid, GridItem } from "@chakra-ui/react";
import Sidebar from "../components/Sidebar";

export default function RootLayout() {
  return (
    <Grid templateColumns="repeat(6, 1fr)" bg="gray.50">
      <GridItem
        as="aside"
        colSpan={{ base: 6, md: 1 }}
        bg="red.700"
        minHeight={{ md: "100vh" }}
        p={{ base: "5px", md: "30px" }}
      >
        <Sidebar />
      </GridItem>
      <GridItem as="main" colSpan={{ base: 6, md: 5 }} p="20px">
        <Navbar />
        <Outlet />
      </GridItem>
    </Grid>
  );
}
