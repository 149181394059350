import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const ResetPassword = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChangePassword = () => {
    if (!password || password.length < 8) {
      return Swal.fire({
        icon: "warning",
        title: "Please Enter a valid password with a length of 8 characters",
      });
    }
    if (password !== confirmPassword) {
      return Swal.fire({
        icon: "warning",
        title: "Passwords do not match",
      });
    }
    const body = {
      id,
      password,
    };
    axios
      .post(`${BASE_URL}/api/admin/reset-password`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        Swal.fire({
          icon: "success",
          title: "Password Changed Successfully, Please Login Again!",
        }).then(() => navigate("/"));
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Some Error Occurred",
        });
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Box
      height={"100vh"}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Flex w={"40%"} gap={2} direction={"column"}>
        <Text align={"center"} fontSize={"20px"} fontWeight={"700"}>
          Reset Password
        </Text>
        <FormLabel>Enter New Password</FormLabel>
        <InputGroup>
          <Input
            type={showPassword ? "text" : "password"}
            placeholder="Enter New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputRightElement>
            {showPassword ? (
              <ViewOffIcon onClick={toggleShowPassword} />
            ) : (
              <ViewIcon onClick={toggleShowPassword} />
            )}
          </InputRightElement>
        </InputGroup>
        <FormLabel>Confirm Password</FormLabel>
        <InputGroup>
          <Input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <InputRightElement>
            {showConfirmPassword ? (
              <ViewOffIcon onClick={toggleShowConfirmPassword} />
            ) : (
              <ViewIcon onClick={toggleShowConfirmPassword} />
            )}
          </InputRightElement>
        </InputGroup>
        <Button
          color={"white"}
          background={"red"}
          onClick={handleChangePassword}
        >
          Submit
        </Button>
      </Flex>
    </Box>
  );
};

export default ResetPassword;
