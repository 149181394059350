const EditDate = (inputDate) => {
    if (!inputDate) {
        return "Not specified";
    }

    const months = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"
    ];

    const parts = inputDate.split("-");
    if (parts.length < 2) return null
    const day = parts[0];
    const month = months[parseInt(parts[1], 10) - 1];
    const year = parts[2];

    return `${day}-${month}-${year}`;
};
export const convertDate = (isoDateString) => {
    if (!isoDateString) {
        return null
    }

    const date = new Date(isoDateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();


    return `${day}-${month}-${year}`;
};
export const splitReverseByDash = (date) => {
    if (date && typeof date == "string") {
        return date.split("-").reverse().join("-")
    }
}
export default EditDate



export function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}
export function getCurrentDateRev() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
}