import {
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  Input,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/hypecircle.jpg";
import BASE_URL from "../utils/BaseURL";
import axios from "axios";
import Swal from "sweetalert2";

const ChangeAttendancePassword = () => {
  const navigate = useNavigate();

  const buttonHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("AttendanceLogin");
    navigate("/attendance");
  };

  const oldPassInput = useRef();
  const newPassInput = useRef();
  const confirmPassInput = useRef();

  const changePassHandler = (event) => {
    event.preventDefault();
    const enteredOldPass = oldPassInput.current.value;
    const enteredNewPass = newPassInput.current.value;
    const enteredConfirmPass = confirmPassInput.current.value;
    if (enteredNewPass !== enteredConfirmPass) {
      return Swal.fire({
        icon: "error",
        title: "Passwords do not Match",
      });
    } else if (enteredOldPass === enteredNewPass) {
      return Swal.fire({
        icon: "error",
        title: "Old & New Password cannot be same.",
      });
    }

    let body = {
      providedAttPass: enteredOldPass,
      newAttPass: enteredConfirmPass,
      email: localStorage.getItem("Email"),
    };

    axios
      .patch(`${BASE_URL}/api/admin/changeAttendancePass`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Password Changed Successfully",
          }).then(() => navigate("/attendance", { replace: true }));
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          text: err?.response?.data?.msg || "Something Went Wrong",
        });
      });
  };

  return (
    <>
      <Flex
        as="nav"
        px="15px"
        py="10px"
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
        bg="red.100"
      >
        <img
          width={"65px"}
          style={{ borderRadius: "50%" }}
          src={logo}
          alt="Hype Logo"
        />

        <Heading>Attendance Panel</Heading>

        <Button onClick={buttonHandler} colorScheme="teal" pos="right">
          Logout
        </Button>
      </Flex>
      <Center p="5">
        <Card width="400px" border="0.2px solid maroon" p="3">
          <Heading size="md" textAlign="center">
            Change Password
          </Heading>
          <CardBody>
            <Input
              m="3"
              placeholder="Enter your old password"
              width="300px"
              ref={oldPassInput}
            />
            <Input
              m="3"
              placeholder="Enter new password"
              width="300px"
              ref={newPassInput}
            />
            <Input
              m="3"
              placeholder="Confirm new password"
              width="300px"
              ref={confirmPassInput}
            />
          </CardBody>
          <Button colorScheme="red" onClick={changePassHandler}>
            Submit
          </Button>
        </Card>
      </Center>
    </>
  );
};

export default ChangeAttendancePassword;
