import {
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { getCurrentDateRev, splitReverseByDash } from "../utils/EditDate";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";

const ExtendMembershipForm = ({
  onClose,
  GetUsers,
  setIsEdit,
  OneUser,
  isOpen,
}) => {
  const [isExtendLoading, setisExtendLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const initial = {
    userID: OneUser._id,
    contact: OneUser.contact,
    mode_of_payment: OneUser.mode_of_payment,
    membership_start_date: OneUser.start_date,
    membership_end_date: OneUser.end_date,
    membership_cost: OneUser.amount,
    balance_pay_date: getCurrentDateRev(),
    payment_amount: "",
    pending_amount: "",
    paid_amount: "",
    remark: "",
  };
  const [extendForm, setExtendForm] = useState(initial);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExtendForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handlePaymentAmountChange = (event) => {
    const { value } = event.target;
    setExtendForm((prevFormData) => ({
      ...prevFormData,

      payment_amount: value,
      paid_amount: OneUser.paid_amount + +value,
      pending_amount: OneUser.pending_amount - value,
      userID: OneUser._id,
      contact: OneUser.contact,
      mode_of_payment: OneUser.mode_of_payment,
      membership_start_date: OneUser.start_date,
      membership_end_date: OneUser.end_date,
      membership_cost: OneUser.amount,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!extendForm.mode_of_payment)
      newErrors.mode_of_payment = "Mode of payment is required";
    if (!extendForm.membership_start_date)
      newErrors.membership_start_date = "Membership start date is required";
    if (!extendForm.membership_end_date)
      newErrors.membership_end_date = "Membership end date is required";
    if (!extendForm.payment_amount || extendForm?.payment_amount <= 10)
      newErrors.payment_amount = "Payment amount must be <= 10";

    if (!extendForm.paid_amount)
      newErrors.paid_amount = "Paid amount is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const ExtendMembershipSubmit = (id) => {
    if (!validateForm()) return;
    setisExtendLoading(true);
    let data = {
      ...extendForm,
      membership_start_date: splitReverseByDash(
        extendForm.membership_start_date
      ),
      membership_end_date: splitReverseByDash(extendForm.membership_end_date),
    };

    axios
      .post(`${BASE_URL}/api/payments/create-payment/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Created Payment for " + OneUser.full_name + " successfully",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      })
      .finally(() => {
        setExtendForm(initial);
        onClose();
        GetUsers(1);
        setisExtendLoading(false);
        setIsEdit(false);
      });
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Extending For Member : {OneUser?.full_name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(6, 1fr)">
            <GridItem as="div" colSpan={{ base: 6, md: 3 }} p="10px">
              {/* <FormLabel>New Membership Start date</FormLabel> */}
              <Text fontWeight={600}>Membership Cost : {OneUser.amount}</Text>
              <Text fontWeight={600}>Paid Amount : {OneUser.paid_amount}</Text>
              <Text fontWeight={600}>
                Pending Amount : {OneUser.pending_amount}
              </Text>

              {/* <Input
                placeholder="Enter Membership start date"
                type="date"
                name="membership_start_date"
                value={extendForm?.membership_start_date}
                onChange={handleInputChange}
                isInvalid={!!errors.membership_start_date}
              />
              {errors.membership_start_date && (
                <div style={{ color: "red" }}>
                  {errors.membership_start_date}
                </div>
              )} */}
            </GridItem>

            <GridItem as="div" colSpan={{ base: 6, md: 3 }} p="10px">
              {/* <FormLabel>New Membership Start date</FormLabel> */}
              <Text fontWeight={600}>
                New Membership Cost : {extendForm.membership_cost}
              </Text>
              <Text fontWeight={600}>
                New Paid Amount : {extendForm.paid_amount}
              </Text>
              <Text fontWeight={600}>
                Pending Amount : {extendForm.pending_amount}
              </Text>
            </GridItem>
            <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
              <FormLabel>Paying Amount</FormLabel>
              <Input
                placeholder="Enter membership cost"
                type="number"
                required={true}
                name="payment_amount"
                value={extendForm.payment_amount}
                max={OneUser.pending_amount}
                onChange={(e) => {
                  const value = e.target.value;
                  value <= OneUser.pending_amount &&
                    handlePaymentAmountChange(e);
                }}
                isInvalid={!!errors.payment_amount}
              />
              {errors.payment_amount && (
                <div style={{ color: "red" }}>{errors.payment_amount}</div>
              )}
            </GridItem>
            {/*
            <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
              <FormLabel>New Membership End Date</FormLabel>
              <Input
                type="date"
                name="membership_end_date"
                value={extendForm?.membership_end_date}
                onChange={handleInputChange}
                isInvalid={!!errors.membership_end_date}
              />
              {errors.membership_end_date && (
                <div style={{ color: "red" }}>{errors.membership_end_date}</div>
              )}
            </GridItem>

            <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
              <FormLabel>Balance Pay Date</FormLabel>
              <Input
                placeholder="Enter Balance Pay Date"
                type="date"
                value={extendForm?.balance_pay_date}
                name="balance_pay_date"
                onChange={handleInputChange}
                isInvalid={!!errors.balance_pay_date}
              />
              {errors.balance_pay_date && (
                <div style={{ color: "red" }}>{errors.balance_pay_date}</div>
              )}
            </GridItem> 
        */}
            <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
              <FormLabel>Pending Amount</FormLabel>
              <Input
                placeholder="Enter pending amount"
                type="number"
                disabled
                value={extendForm.pending_amount}
                name="pending_amount"
                onChange={handleInputChange}
                isInvalid={!!errors.pending_amount}
              />
              {/* {errors.pending_amount && (
                <div style={{ color: "red" }}>{errors.pending_amount}</div>
              )} */}
            </GridItem>
            <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
              <FormLabel>Paid Amount</FormLabel>
              <Input
                placeholder="Enter amount paid"
                type="number"
                disabled
                value={extendForm.paid_amount}
                name="paid_amount"
                onChange={handleInputChange}
                isInvalid={!!errors.paid_amount}
              />
              {errors.paid_amount && (
                <div style={{ color: "red" }}>{errors.paid_amount}</div>
              )}
            </GridItem>
            <GridItem as="div" colSpan={{ base: 6, md: 2 }} p="10px">
              <FormLabel>Mode of payment</FormLabel>
              <Select
                placeholder="Select option"
                name="mode_of_payment"
                required={true}
                value={extendForm?.mode_of_payment}
                onChange={handleInputChange}
                isInvalid={!!errors.mode_of_payment}
              >
                <option value="UPI">UPI</option>
                <option value="Online">Online</option>
                <option value="Cash">Cash</option>
                <option value="Card">Card</option>
                <option value="Cheque">Cheque</option>
              </Select>
              {errors.mode_of_payment && (
                <div style={{ color: "red" }}>{errors.mode_of_payment}</div>
              )}
            </GridItem>
            <GridItem as="div" colSpan={{ base: 4, md: 4 }} p="10px">
              <FormLabel>Remark</FormLabel>
              <Input
                placeholder="Enter remark"
                type="text"
                name="remark"
                value={extendForm?.remark || ""}
                onChange={handleInputChange}
              />
            </GridItem>
            <GridItem as="div" colSpan={{ base: 6, md: 6 }} p="5px">
              <Flex justifyContent={"end"} gap={"2"}>
                <Button
                  colorScheme="green"
                  onClick={() => ExtendMembershipSubmit(OneUser._id, OneUser)}
                  isLoading={isExtendLoading}
                >
                  Create Payment
                </Button>
                <Button colorScheme="red" onClick={() => onClose()}>
                  Cancel
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExtendMembershipForm;
