import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  Text,
  Center,
  Flex,
} from "@chakra-ui/react";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";

const Trainers = () => {
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrainers = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/trainers/getAllTrainers`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();

        setTrainers(data.trainers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching trainers:", error);
        setLoading(false);
      }
    };

    fetchTrainers();
  }, []);

  if (loading) {
    return (
      <Center mt="20">
        <Spinner size="xl" />
      </Center>
    );
  }

  const handleEdit = (trainerId) => {
    navigate(`/dashboard/trainers/edit/${trainerId}`);
  };

  const handleDelete = async (trainerId) => {
    const confirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirm.isConfirmed) {
      try {
        const response = await fetch(`${BASE_URL}/api/trainers/${trainerId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.ok) {
          setTrainers((prevTrainers) =>
            prevTrainers.filter((trainer) => trainer._id !== trainerId)
          );
          Swal.fire("Deleted!", "The trainer has been deleted.", "success");
        } else {
          Swal.fire("Error!", "Failed to delete the trainer.", "error");
        }
      } catch (error) {
        console.error("Error deleting trainer:", error);
        Swal.fire("Error!", "Failed to delete the trainer.", "error");
      }
    }
  };

  return (
    <Box mx={"10px"} mt={"10px"}>
      <Flex justifyContent={"space-between"} my={"2"} mx={"1"}>
        <Text fontSize="1.5rem" mb="10px">
          List of Trainers:
        </Text>
        <Button
          colorScheme="red"
          onClick={() => navigate("/dashboard/trainers/add")}
        >
          Add New Trainer
        </Button>
      </Flex>
      <Container maxW="container.xl" p="0">
        <TableContainer border="0.5px solid" borderRadius="0px">
          <Table variant="striped" colorScheme="red" size="sm">
            <TableCaption>All Registered Trainers</TableCaption>
            <Thead>
              <Tr>
                <Th>S.no</Th>
                <Th>Image</Th>
                <Th>Full Name</Th>
                <Th>Date of Joining</Th>
                <Th>Address</Th>
                <Th>Contact Number</Th>
                <Th>Email</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {trainers.map((trainer, ind) => (
                <Tr key={trainer._id}>
                  <Td>{ind + 1}</Td>
                  <Td>
                    <img
                      src={trainer.image}
                      alt={trainer.full_name}
                      width="50px"
                    />
                  </Td>
                  <Td>{trainer.full_name}</Td>

                  <Td>
                    {new Date(trainer.date_of_joining).toLocaleDateString()}
                  </Td>
                  <Td>{trainer.address}</Td>
                  <Td>{trainer.contact_number}</Td>
                  <Td>{trainer.email}</Td>
                  <Td>
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => handleEdit(trainer._id)}
                    >
                      Edit
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleDelete(trainer._id)}
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default Trainers;
