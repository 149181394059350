import {
    Button,
    Flex,
    FormLabel,
    Grid,
    GridItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
  } from "@chakra-ui/react";
  import axios from "axios";
  import React, { useState } from "react";
  import Swal from "sweetalert2";
  import BASE_URL from "../utils/BaseURL";
  import EditDate, { splitReverseByDash } from "../utils/EditDate";
  
  const ResumeMembership = ({ onClose, GetUsers, OneUser, isOpen }) => {
    const [isResumeLoading, setIsResumeLoading] = useState(false);
  
    const handleResumeSubmit = () => {
      setIsResumeLoading(true);
  
      axios
        .put(
          `${BASE_URL}/api/payments/resume-membership/${OneUser._id}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Membership resumed successfully",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
            text: err.response ? err.response.data.message : "Unexpected error",
          });
        })
        .finally(() => {
          onClose();
          GetUsers(1);
          setIsResumeLoading(false);
        });
    };
  
    return (
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Resume Membership for: {OneUser?.full_name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(1, 1fr)" gap={6}>
              <GridItem>
                <Text fontWeight={600}>
                  Membership Start Date: {splitReverseByDash(EditDate(OneUser.start_date))}
                </Text>
                <Text fontWeight={600}>
                  Membership End Date: {splitReverseByDash(EditDate(OneUser.end_date))}
                </Text>
                <Text fontWeight={600} color="red">
                  * Are you sure you want to resume your paused membership?
                </Text>
              </GridItem>
              <GridItem>
                <Flex justifyContent={"end"} gap={"2"}>
                  <Button
                    colorScheme="green"
                    onClick={handleResumeSubmit}
                    isLoading={isResumeLoading}
                  >
                    Resume Membership
                  </Button>
                  <Button colorScheme="red" onClick={onClose}>
                    Cancel
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };
  
  export default ResumeMembership;
  