import {
  Center,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
} from "@chakra-ui/react";
import Filters from "./Filters";
import { useState, useEffect } from "react";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import axios from "axios";
import { EditTime } from "../utils/EditTime";
import EditDate, { getCurrentDateRev } from "../utils/EditDate";

const AdminAttendanceTable = () => {
  const [Attendance, setAttendance] = useState([]);

  useEffect(() => {
    let makereq = localStorage.getItem("isLoggedIn");
    if (makereq) {
      GetAllAttendance();
    }
    return () => (makereq = false);
  }, []);

  const GetAllAttendance = (current) => {
    if (!current) {
      current = getCurrentDateRev();
    }

    axios
      .get(`${BASE_URL}/api/attendance/getAllAttendance?date=${current}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let filteredAttendance = res?.data?.Attendances?.reverse();
        setAttendance(filteredAttendance);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "500 Server Error",
          text: err,
        });
      });
  };

  return (
    <Card>
      <CardHeader>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Heading size={{ base: "sm", md: "md" }} m="3" textAlign="center">
            View Attendance Table
          </Heading>
          <Filters
            GetAllAttendance={GetAllAttendance}
            setAttendance={setAttendance}
            Attendance={Attendance}
          />
        </Stack>
      </CardHeader>
      <CardBody>
        <TableContainer
          border="0.5px solid"
          p="2"
          mx={{ md: 5 }}
          borderRadius="5px"
        >
          <Table
            variant="striped"
            colorScheme="red"
            size="sm"
            style={{ overflowY: "scroll" }}
          >
            <Thead>
              <Tr>
                <Th>S.No.</Th>
                <Th>Name</Th>
                <Th>Date</Th>
                <Th>Check in Time</Th>
                {/* <Th>Balance Amount</Th> */}
                <Th>Start Date</Th>
                <Th>End Date</Th>
                <Th>Mobile</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Attendance?.length > 0 ? (
                Attendance?.map((mark, index) => (
                  <Tr key={index + "attendance" + mark?.membershipID}>
                    <Td>{index + 1}</Td>
                    <Td>{mark?.name}</Td>
                    <Td>
                      {EditDate(mark?.date?.split("-").reverse().join("-"))}
                    </Td>
                    <Td>{EditTime(mark?.check_in_time)}</Td>
                    <Td>{EditDate(mark?.userID?.start_date)}</Td>
                    <Td>{EditDate(mark?.userID?.end_date)}</Td>
                    <Td>+91 {mark?.contact}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={12}>
                    <Center m="5px">No Attendance Available</Center>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default AdminAttendanceTable;
