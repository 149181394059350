import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Text,
  Center,
  TableContainer,
} from "@chakra-ui/react";
import BASE_URL from "../utils/BaseURL";

const TrainerReviews = () => {
  const { trainerId } = useParams();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTrainerReviews = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/reviews/getReviewsByTrainer/${trainerId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        setReviews(data.reviews);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching trainer reviews:", error);
        setLoading(false);
      }
    };

    fetchTrainerReviews();
  }, [trainerId]);

  const [expandedComments, setExpandedComments] = useState([]);

  const toggleExpand = (index) => {
    const newExpanded = [...expandedComments];
    newExpanded[index] = !newExpanded[index];
    setExpandedComments(newExpanded);
  };

  if (loading) {
    return (
      <Center mt="20">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box mx={"10px"} mt={"10px"}>
      <Text fontSize="1.5rem" mb="10px">
        Reviews for Trainer
      </Text>
      <Container maxW="container.xl" p="0">
        <TableContainer border="0.5px solid" borderRadius="0px">
          <Table variant="striped" colorScheme="red" size="md">
            <Thead>
              <Tr>
                <Th>S.no</Th>
                <Th>Member</Th>
                <Th>Rating</Th>
                <Th>Comment</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reviews.map((review, ind) => (
                <Tr key={review._id}>
                  <Td>{ind + 1}</Td>
                  <Td>{review.memberId.full_name}</Td>
                  <Td>{review.ratings}</Td>
                  <Td>
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {expandedComments[ind] ? review.comment : `${review.comment.substring(0, 50)}...`}
                    </div>
                    {review.comment.length > 50 && (
                      <Button
                        ml="2"
                        size="xs"
                        onClick={() => toggleExpand(ind)}
                        variant="link"
                        colorScheme="blue"
                      >
                        {expandedComments[ind] ? "Show Less" : "Show More"}
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
      <Button mt="4" colorScheme="gray" onClick={() => navigate("/dashboard/reviews")}>
        Back to Reviews
      </Button>
    </Box>
  );
};

export default TrainerReviews;
