import React, { useState, useEffect } from "react";
import { Flex, Input, Box } from "@chakra-ui/react";
import BASE_URL from "../utils/BaseURL";
import axios from "axios";
import Swal from "sweetalert2";
import { getCurrentDate } from "../utils/EditDate";
import { GetCurrTime } from "../utils/EditTime";

const UserSearchBar = ({ GetAllAttendance }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchBar, setSearchBar] = useState("");
  const [filteredUsers, setfilteredUsers] = useState([]);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    const GetFilteredUsers = () => {
      axios
        .get(`${BASE_URL}/api/users/searchUser?search=${searchQuery}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setfilteredUsers(res?.data?.Users);
        })
        .catch((err) => console.log(err));
    };
    const Timer = setTimeout(() => {
      if (searchQuery !== "") {
        GetFilteredUsers();
      }
    }, 500);
    return () => {
      setfilteredUsers([]);
      clearTimeout(Timer);
    };
  }, [searchQuery]);

  const HandleAttendanceSubmit = (user) => {
    if (user.isPaused) {
      return Swal.fire({
        icon: "warning",
        text: "Membership is paused for this person, cannot mark attendance.",
      });
    }
    setfilteredUsers([]);
    setSearchBar(
      `${user.full_name} - email: ${user.email} - phone: ${user.contact}`
    );
    let currentdate = new Date();
    let enddate = new Date(user?.end_date?.split("-").reverse().join("-"));
    let isExpired = currentdate >= enddate;
    if (isExpired) {
      return Swal.fire({
        icon: "warning",
        text: "Membership is expired for this person, cannot mark his attendance.",
      });
    }
    setloading(true);
    let body = {
      name: user.full_name,
      membershipID: user.membershipID,
      email: user.email,
      userID: user._id,
      contact: user.contact,
      date: getCurrentDate().split("-").reverse().join("-"),
      check_in_time: GetCurrTime(),
    };

    axios
      .post(`${BASE_URL}/api/attendance/markAttendance`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: res.data.msg,
        });
        GetAllAttendance();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: "501 Server Error",
        });
      })
      .finally(() => {
        setloading(false);
        setSearchBar("");
      });
  };

  const liststyles = {
    padding: "6px 12px",
    border: "0.1px solid pink",
    borderRadius: "2px",
    cursor: "pointer",
  };
  const UlStyles = {
    cursor: "pointer",
    position: "absolute",
    background: "white",
    zIndex: "5",
    width: "100%",
    listStyle: "none",
    maxHeight: "500px",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  const closedropdown = (e) => {
    if (!e.target.matches("[data-dropdown]")) {
      setfilteredUsers([]);
    }
  };
  return (
    <Flex width={"100%"} onClick={closedropdown} bg="white">
      <Box width={"100%"} style={{ position: "relative" }}>
        <Input
          isDisabled={loading}
          type="text"
          value={searchBar}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setSearchBar(e.target.value);
          }}
          placeholder="Search for a A User..."
        />

        {filteredUsers.length > 0 && (
          <ul style={UlStyles} data-dropdown>
            {filteredUsers.map((user, index) => (
              <li
                title="Click to Mark User's Attendance"
                onClick={() => HandleAttendanceSubmit(user)}
                style={liststyles}
                key={index}
              >
                {`${user.full_name} - email: ${user.email} - phone: ${user.contact}`}
              </li>
            ))}
          </ul>
        )}
      </Box>

      {/* <Button
        mx={5}
        colorScheme="red"
        onClick={HandleAttendanceSubmit}
        isLoading={loading}
      >
        Submit
      </Button> */}
    </Flex>
  );
};

export default UserSearchBar;
