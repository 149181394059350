import { Box, Grid, GridItem, Heading, Spinner } from "@chakra-ui/react";
import IndianNumberSystem from "../utils/IndianNumberSystem";

export default function Stats({ Data }) {
  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      m={{ base: 2, md: 5 }}
      bgColor="gray.100"
    >
      <GridItem
        as="div"
        colSpan={{ base: 3, md: 1 }}
        p={{ base: "5px", md: "10px" }}
      >
        <Box
          bg="red.600"
          borderRadius={5}
          height={{ base: "80px", md: "100px" }}
          color="white"
          textAlign="center"
          p={{ base: 1, md: 4 }}
        >
          Total members
          <Heading>
            {Data?.TotalMembers ? (
              IndianNumberSystem(+Data?.TotalMembers)
            ) : (
              <Spinner />
            )}
          </Heading>
        </Box>
      </GridItem>
      <GridItem
        as="div"
        colSpan={{ base: 3, md: 1 }}
        p={{ base: "5px", md: "10px" }}
      >
        <Box
          bg="red.600"
          borderRadius={5}
          height={{ base: "80px", md: "100px" }}
          color="white"
          textAlign="center"
          p={{ base: 1, md: 4 }}
        >
          Membership Due
          <Heading>
            {Data?.MembershipDue ? (
              IndianNumberSystem(+Data?.MembershipDue)
            ) : (
              <Spinner />
            )}
          </Heading>
        </Box>
      </GridItem>
      <GridItem
        as="div"
        colSpan={{ base: 3, md: 1 }}
        p={{ base: "5px", md: "10px" }}
      >
        <Box
          bg="red.600"
          borderRadius={5}
          height={{ base: "80px", md: "100px" }}
          color="white"
          textAlign="center"
          p={{ base: 1, md: 4 }}
        >
          Monthly Revenue
          <Heading>
            ₹{" "}
            {Data?.MonthlyRevenue === 0 ? (
              0
            ) : Data?.MonthlyRevenue ? (
              IndianNumberSystem(+Data?.MonthlyRevenue)
            ) : (
              <Spinner />
            )}
          </Heading>
        </Box>
      </GridItem>
    </Grid>
  );
}
