import {
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import BASE_URL from "../utils/BaseURL";
import EditDate, { convertDate, splitReverseByDash } from "../utils/EditDate";

const PauseMembershipForm = ({ onClose, GetUsers, OneUser, isOpen }) => {
  const [isPauseLoading, setIsPauseLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [pauseDays, setPauseDays] = useState("");

  const membershipType = () => {
    const diffInMonths = Math.floor(
      (new Date(OneUser.end_date) - new Date(OneUser.start_date)) /
        (1000 * 60 * 60 * 24 * 30)
    );
    if (diffInMonths >= 12) return "1-year";
    if (diffInMonths >= 6) return "6-month";
    return "3-month";
  };

  const maxPauseDays = () => {
    const type = membershipType();
    if (type === "1-year") return 30;
    if (type === "6-month") return 21;
    return 10;
  };

  const validateForm = () => {
    const newErrors = {};
    if (!pauseDays) newErrors.pauseDays = "Pause days are required";
    if (pauseDays > maxPauseDays())
      newErrors.pauseDays = `Pause days cannot exceed ${maxPauseDays()} days`;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePauseSubmit = () => {
    if (!validateForm()) return;
    setIsPauseLoading(true);
   

    axios 
      .put(
        `${BASE_URL}/api/payments/pause-membership/${OneUser._id}`,
        {pause_days: pauseDays },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Membership paused successfully",
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.msg === "Membership can only be paused once during the membership period") {
          Swal.fire({
            icon: "warning",
            title: "Warning",
            text: "Membership can only be paused once during the membership period",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Something went wrong",
          });
        }
      })
      .finally(() => {
        onClose();
        GetUsers(1);
        setIsPauseLoading(false);
      });
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pause Membership for: {OneUser?.full_name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(1, 1fr)" gap={6}>
            <GridItem>
              <Text fontWeight={600}>Membership Start Date:  {splitReverseByDash(EditDate(OneUser.start_date))}</Text>
              <Text fontWeight={600}>Membership End Date:  {splitReverseByDash(EditDate(OneUser.end_date))}</Text>
              <Text fontWeight={600}>Max Pause Days: {maxPauseDays()}</Text>
              <FormLabel mt={4}>Pause Days</FormLabel>
              <Input
                type="number"
                name="pauseDays"
                value={pauseDays}
                onChange={(e) => setPauseDays(e.target.value)}
                isInvalid={!!errors.pauseDays}
              />
              {errors.pauseDays && <Text color="red">{errors.pauseDays}</Text>}
            </GridItem>
            <GridItem>
              <Flex justifyContent={"end"} gap={"2"}>
                <Button
                  colorScheme="green"
                  onClick={handlePauseSubmit}
                  isLoading={isPauseLoading}
                >
                  Pause Membership
                </Button>
                <Button colorScheme="red" onClick={onClose}>
                  Cancel
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PauseMembershipForm;
