import axios from "axios";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import { daysLeft } from "../utils/Calculations";
import React, { useEffect, useState } from "react";


import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  TabPanel,

} from "@chakra-ui/react";
import EditDate from "../utils/EditDate";
import PaginationBox from "./Pagination";


const ExpireList = ({ Expiring }) => {
  const [page, setpage] = useState(1);
  const usersPerPage = 10;

  const HandleNotify = (user, type) => {
    const passing = user.end_date?.split("-").join("/");
    let body = {
      name: user.full_name,
      useremail: user.email,
      daysleft: daysLeft(passing),
      passing,
      type: type,
    };
    axios
      .post(`${BASE_URL}/api/notify/${type}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Notification Sent Successfully",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          text: "Something Went Wrong",
          icon: "error",
          title: "Notification Not Sent",
        });
        console.log(err);
      });
  };

  const paginatedExpire = Expiring.slice((page - 1) * usersPerPage, page * usersPerPage);


  return (
    <TabPanel>

    <TableContainer border="0.5px solid" borderRadius="5px" p={{ md: 2 }}>
      <Table
        variant="striped"
        colorScheme="red"
        size="sm"
        style={{ overflowY: "scroll", maxHeight: "500px" }}
      >
        <Thead>
          <Tr>
            <Th sx={headCellStyle}>S.no</Th>
            <Th sx={headCellStyle}>Membership ID</Th>
            <Th sx={headCellStyle}> Name</Th>
            <Th sx={headCellStyle}>Start Date</Th>
            <Th sx={headCellStyle}>End Date</Th>
            <Th sx={headCellStyle}>Contact</Th>
            <Th sx={headCellStyle}>Email</Th>
            <Th sx={headCellStyle}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {paginatedExpire.length > 0 ? (
            paginatedExpire.map((user, index) => (
              <Tr key={index + "allusers" + user.membershipID}>
              <Td sx={cellStyle}>{index + 1 + (page - 1) * usersPerPage}</Td>
                <Td sx={cellStyle}>
                  <b>{user.membershipID}</b>
                </Td>
                <Td sx={cellStyle}>{user.full_name}</Td>
                <Td sx={cellStyle}>{EditDate(user.start_date)}</Td>
                <Td sx={cellStyle}>{EditDate(user.end_date)}</Td>
                <Td sx={cellStyle}>
                  {" "}
                  {user.contact ? user.contact : "Not Provided"}
                </Td>
                <Td sx={cellStyle}>{user.email || "Not Provided"}</Td>
                <Td sx={cellStyle}>
                  <Button
                    colorScheme="red"
                    color="white"
                    size="sm"
                    m={0}
                    isDisabled={!user.email}
                    onClick={() => HandleNotify(user, "expiring")}
                  >
                    Notify
                  </Button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td sx={cellStyle} colSpan={9}>
                <center>No Users</center>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
    <PaginationBox
        TotalMembers={Expiring.length}
        setpage={setpage}
        page={page}
      />
      </TabPanel>
  );
};
const headCellStyle = {
  padding: "10px",
  color: "black",
};
const cellStyle = {
  padding: "10px",
};
export default ExpireList;
