import AttendanceHeader from "../components/AttendanceHeader";
import AttendanceLogin from "../components/AttendanceLogin";
import AttendanceTable from "../components/AttendanceTable";

export default function Attendance() {
  const AttendanceLog = localStorage.getItem("AttendanceLogin");
  return AttendanceLog ? (
    <>
      <AttendanceHeader />
      <AttendanceTable />
    </>
  ) : (
    <AttendanceLogin />
  );
}
