import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  Center,
} from "@chakra-ui/react";
import BASE_URL from "../utils/BaseURL";
import Swal from "sweetalert2";
import uploadFileToS3 from '../utils/AWS-Upload';

const EditTrainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    full_name: "",
    date_of_joining: "",
    address: "",
    contact_number: "",
    email: "",
    image: "",
  });
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchTrainer = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/trainers/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        setFormData(data.trainer);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching trainer:", error);
        setLoading(false);
      }
    };

    fetchTrainer();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === 'image') {
      setImageFile(files[0]);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      let imageUrl = formData.image;
      if (imageFile) {
        const uploadResult = await uploadFileToS3(imageFile);
        imageUrl = uploadResult.Location;
      }

      const trainerData = { ...formData, image: imageUrl };

      const response = await fetch(`${BASE_URL}/api/trainers/${id}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(trainerData),
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Trainer Updated",
          text: "The trainer's details have been updated successfully.",
        });
        navigate("/dashboard/trainers");
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorData.message,
        });
      }
    } catch (error) {
      console.error("Error updating trainer:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating the trainer's details.",
      });
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <Center mt="20">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box mx={"10px"} mt={"10px"}>
      <Text fontSize="1.5rem" mb="10px">
        Edit Trainer:
      </Text>
      <Container as="form" onSubmit={handleSubmit}>
        <FormControl mb="4">
          <FormLabel>Full Name</FormLabel>
          <Input
            type="text"
            name="full_name"
            value={formData.full_name}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel>Date of Joining</FormLabel>
          <Input
            type="date"
            name="date_of_joining"
            value={formData.date_of_joining}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel>Address</FormLabel>
          <Input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel>Contact Number</FormLabel>
          <Input
            type="text"
            name="contact_number"
            value={formData.contact_number}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mb="4">
          <FormLabel>Image</FormLabel>
          <Input
            type="file"
            name="image"
            onChange={handleInputChange}
          />
          {formData.image && (
            <Box mt="2">
              <Text>Current Image:</Text>
              <img src={formData.image} alt="Trainer" width="100px" />
            </Box>
          )}
        </FormControl>
        <Button type="submit" colorScheme="blue" isLoading={loading}>
          Save Changes
        </Button>
        <Button
          ml="4"
          colorScheme="gray"
          onClick={() => navigate("/dashboard/trainers")}
        >
          Cancel
        </Button>
      </Container>
    </Box>
  );
};

export default EditTrainer;
