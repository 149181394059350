import {
  ChevronDownIcon,
  EditIcon,
  ExternalLinkIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Flex,
  HStack,
  Heading,
  Spacer,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";

export default function Navbar() {
  const email = localStorage.getItem("Email");
  const navigate = useNavigate();

  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  return (
    <Flex
      as="nav"
      p={{ base: "6px", md: "10px" }}
      alignItems="center"
      gap={{ base: "4px", md: "10px" }}
    >
      <Heading as="h1"> Hype Gym </Heading>
      <Spacer />
      <HStack spacing="20px">
        {email && (
          <Menu>
            <MenuButton
              as={Button}
              aria-label="Options"
              rightIcon={<ChevronDownIcon />}
            >
              Admin
            </MenuButton>
            <MenuList>
              <MenuItem icon={<EditIcon />}>Profile</MenuItem>
              <MenuItem
                as={NavLink}
                to="/dashboard/changepassword"
                icon={<SettingsIcon />}
              >
                Change Password
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        {email && (
          <Button
            colorScheme="red"
            onClick={logoutHandler}
            rightIcon={<ExternalLinkIcon />}
          >
            Logout
          </Button>
        )}
      </HStack>
    </Flex>
  );
}
